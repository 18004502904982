import { useEffect, createContext, Dispatch, useReducer, useContext } from "react";
import { Skeleton } from "@chakra-ui/react";
import { setAuthTokenHeaders } from "../../../../api/auth";
import { setBaseUrl } from "../../../../api/apiOSS";
import { EhrDetails } from "../../../../domain/ehr";

import {
  initialState,
  SharedState,
  SharedStateAction,
  reducer,
  SharedStateActionType,
} from "./reducer";

interface SharedStateContext {
  sharedState: SharedState;
  sharedDispatch: Dispatch<SharedStateAction>;
}

export const SharedStateContext = createContext<SharedStateContext>({
  sharedState: initialState,
  sharedDispatch: () => null,
});

export const SharedStateProvider = ({
  authToken,
  baseUrl,
  ehrDetails,
  children,
}: {
  authToken: string;
  baseUrl: string;
  ehrDetails: EhrDetails | undefined;
  children: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}) => {
  const [sharedState, sharedDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    function setShared() {
      setAuthTokenHeaders(authToken);
      setBaseUrl(baseUrl);
      sharedDispatch({
        type: SharedStateActionType.set,
        authToken,
        baseUrl,
        ehrDetails,
        isLoaded: true,
      });
    }
    setShared();
  }, [authToken, baseUrl]);

  // Prevent that the UI/components are rendered before set the auth token
  if (!sharedState.isLoaded) return <Skeleton height="100vh" width="100vw" />;

  return (
    <SharedStateContext.Provider value={{ sharedState, sharedDispatch }}>
      {children}
    </SharedStateContext.Provider>
  );
};

export const useSharedContext = () => useContext(SharedStateContext);
