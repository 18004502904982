import {
  Medication,
  MedicationAdministration,
  MedicationDispense,
  MedicationStatement,
  MedicationRequest,
} from "@medplum/fhirtypes";
import { MedicationRowData } from "./table-data";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { getResourcesFromBundle } from "../shared";
import { getLatestAdministered, getMedicationWithRefs, getMedicationDose } from "./table-data";
import { DetailsDisplay } from "../shared/details";
import { MedicationHistory } from "./history";
import { MedicationWithRefs } from "./table-data";

export function MedicationContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & MedicationRowData) {
  const selectedMedication = mappedConsolidated?.["Medication"]?.[rowContents.id] as
    | Medication
    | undefined;

  const medicationAdministration = getResourcesFromBundle<MedicationAdministration>(
    mappedConsolidated,
    "MedicationAdministration"
  );
  const medicationDispense = getResourcesFromBundle<MedicationDispense>(
    mappedConsolidated,
    "MedicationDispense"
  );
  const medicationStatement = getResourcesFromBundle<MedicationStatement>(
    mappedConsolidated,
    "MedicationStatement"
  );
  const medicationRequest = getResourcesFromBundle<MedicationRequest>(
    mappedConsolidated,
    "MedicationRequest"
  );

  const medicationWithRefs = getMedicationWithRefs(
    selectedMedication ?? rowContents.raw.medication,
    medicationAdministration,
    medicationDispense,
    medicationStatement,
    medicationRequest
  );

  const valueString =
    selectedMedication?.extension?.find(ext => ext.valueString)?.valueString ??
    getValueStringFromMedRef(medicationWithRefs.statement)[0] ??
    getValueStringFromMedRef(medicationWithRefs.administration)[0] ??
    getValueStringFromMedRef(medicationWithRefs.dispense)[0] ??
    getValueStringFromMedRef(medicationWithRefs.requests)[0] ??
    "";

  return (
    <SidePanelContent
      title="Medication"
      sourceDocument={{
        id: selectedMedication?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedMedication, null, 2) : undefined}
    >
      <MedicationDisplay tableRow={rowContents} medicationWithRefs={medicationWithRefs} />
    </SidePanelContent>
  );
}

function getValueStringFromMedRef(
  medRef:
    | MedicationAdministration[]
    | MedicationRequest[]
    | MedicationStatement[]
    | MedicationDispense[]
) {
  return medRef.map(med => {
    return med.extension?.find(ext => ext.valueString)?.valueString ?? "";
  });
}

function MedicationDisplay({
  tableRow,
  medicationWithRefs,
}: {
  tableRow: MedicationRowData;
  medicationWithRefs: MedicationWithRefs;
}) {
  const latestAdministered = getLatestAdministered(medicationWithRefs.administration);

  const dosage = getMedicationDose(medicationWithRefs);
  const route = latestAdministered?.dosage?.route?.text;

  return (
    <>
      <DetailsDisplay
        details={{
          name: tableRow.medication,
          status: latestAdministered?.status,
          dosage,
          route,
        }}
      />
      <MedicationHistory medicationWithRef={medicationWithRefs} />
    </>
  );
}
