import { useState } from "react";
import {
  PatientCreate,
  BaseUpdate,
  usStateForAddressSchema,
  DriverLicenseIdentifier,
  usStateSchema,
  PatientDTO,
} from "@metriport/api-sdk";
import { cloneDeep } from "lodash";
import { useMetriportApi } from "./useMetriportApi";
import { PatientFormSchema, DRIVERS_LICENSE } from "../shared-components/patient-form";
import { filterTruthy } from "./filter-map-utils";
import { useAnalyticsContext, Actions, Features } from "./analytics-context";
import useMetriportToast from "./useMetriportToast";

type UseGetSubmitPatient = {
  createOrUpdatePatient: (patientForm: PatientFormSchema) => Promise<PatientDTO | undefined>;
  isSubmitting: boolean;
};

export function useSubmitPatient({
  patientId,
  onComplete,
}: {
  patientId?: string;
  onComplete: () => void;
}): UseGetSubmitPatient {
  const metriportApi = useMetriportApi();
  const Analytics = useAnalyticsContext();
  const { success, warning } = useMetriportToast();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const createOrUpdatePatient = async (
    patientForm: PatientFormSchema
  ): Promise<PatientDTO | undefined> => {
    const patient = formToApi(patientForm);

    setIsSubmitting(true);

    try {
      let resp: PatientDTO;
      if (patientId) {
        Analytics.emit(Actions.update, Features.patient);
        resp = await metriportApi.updatePatient(
          {
            ...patient,
            id: patientId,
          },
          patientForm.facilityId
        );

        success({ title: "Patient saved." });
      } else {
        Analytics.emit(Actions.create, Features.patient);
        resp = await metriportApi.createPatient(patient, patientForm.facilityId);
      }

      return resp;
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response?.status === 423) {
        warning({
          title: "Patient temporarily locked",
          description:
            "The patient can't be updated while Document Query is in progress. Please try again later or reach out to support@metriport.com if this is not expected.",
          duration: 5_000,
        });
      }

      return undefined;
    } finally {
      setIsSubmitting(false);
      onComplete();
    }
  };

  return { createOrUpdatePatient, isSubmitting };
}

const formToApi = (form: PatientFormSchema): PatientCreate & Partial<BaseUpdate> => {
  const formCopy = cloneDeep(form);
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (formCopy as any).driversLicense; // safe on this context, don't use formCopy for anything else
  const personalIdentifiers: DriverLicenseIdentifier[] = [
    form.driversLicense.value && form.driversLicense.state
      ? {
          type: DRIVERS_LICENSE,
          value: form.driversLicense.value,
          state: usStateSchema.parse(form.driversLicense.state),
        }
      : undefined,
  ]
    .flatMap(filterTruthy)
    .filter(pid => pid.type.trim().length > 0 && pid.value.trim().length > 0);
  return {
    ...formCopy,
    contact: form.contact.filter(c => c.phone !== undefined || c.email !== undefined),
    address: [
      {
        ...form.address[0],
        state: usStateForAddressSchema.parse(form.address[0].state),
      },
      ...form.address.slice(1).map(address => {
        return {
          ...address,
          state: usStateForAddressSchema.parse(address.state),
        };
      }),
    ],
    personalIdentifiers,
  };
};
