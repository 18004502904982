import { createContext, Dispatch, useReducer, useContext } from "react";

import {
  initialState,
  PatientConsolidatedState,
  PatientConsolidatedStateAction,
  reducer,
} from "./reducer";

interface PatientConsolidatedStateContext {
  patientConsolidatedState: PatientConsolidatedState;
  patientConsolidatedDispatch: Dispatch<PatientConsolidatedStateAction>;
}

export const PatientConsolidatedStateContext = createContext<PatientConsolidatedStateContext>({
  patientConsolidatedState: initialState,
  patientConsolidatedDispatch: () => null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PatientConsolidatedStateProvider = ({ children }: { children: any }) => {
  const [patientConsolidatedState, patientConsolidatedDispatch] = useReducer(reducer, initialState);

  return (
    <PatientConsolidatedStateContext.Provider
      value={{ patientConsolidatedState, patientConsolidatedDispatch }}
    >
      {children}
    </PatientConsolidatedStateContext.Provider>
  );
};

export const usePatientConsolidatedContext = () => useContext(PatientConsolidatedStateContext);
