import { Box, Heading, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { Button } from "../button";
import { useDownloadFile } from "../../shared-logic/useDownloadFile";

export function SidePanelContent({
  title,
  sourceDocument,
  fhirJson,
  children,
}: {
  title: string;
  sourceDocument: {
    id: string;
    fileName: string;
  };
  fhirJson?: string;
  children: React.ReactNode;
}) {
  const { isDownloading, onDownloadFile } = useDownloadFile();
  const [stateJson, setJson] = useState<string | undefined>(undefined);

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading mb="3">{title}</Heading>
        <Box>
          <Button
            isLoading={isDownloading[sourceDocument.id]?.downloading ?? false}
            onClick={() => {
              onDownloadFile({
                docId: sourceDocument.id,
                docFilename: sourceDocument.fileName,
                conversionType: "html",
              });
            }}
          >
            View Source
          </Button>
          {fhirJson && (
            <Button
              ml={2}
              isLoading={isDownloading[sourceDocument.id]?.downloading ?? false}
              onClick={() => {
                setJson(stateJson ? undefined : fhirJson);
              }}
            >
              {stateJson ? "X" : JSON.stringify({})}
            </Button>
          )}
        </Box>
      </Flex>

      {stateJson ? <pre style={{ whiteSpace: "pre-wrap" }}>{stateJson}</pre> : children}
    </Box>
  );
}
