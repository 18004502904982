import { ColDef } from "ag-grid-community";
import { ISO_DATE } from "@metriport/shared/common/date";
import { Condition } from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { MrFilterSetting } from "../../../../../api/settings";
import {
  getFirstCodeSpecified,
  ICD_10_CODE,
  SNOMED_CODE,
  compare,
  filterByDate,
  getValidCode,
} from "../shared";
import { GenerateTableDataParams } from "..";
import { getResourcesFromBundle } from "../shared";

export type ConditionRowData = {
  id: string;
  condition: string;
  code: string;
  firstSeen: string;
  lastSeen: string;
};

type ConditionOccurrence = {
  rawCondition: Condition;
  start: string | undefined;
  end: string | undefined;
  status: string | undefined;
};

export type GroupedConditions = {
  title: string;
  mostRecentCondition: Condition;
  sortedOccurrences?: ConditionOccurrence[];
  status?: string | undefined;
};

export const conditionTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<ConditionRowData>[] = [
    { field: "id", hide: true },
    { field: "condition" },
    { field: "code" },
    { field: "firstSeen", sort: tableFilters?.stringFilter ? undefined : "desc" },
    { field: "lastSeen", sort: tableFilters?.stringFilter ? undefined : "desc" },
  ];

  const conditions = getResourcesFromBundle<Condition>(bundle, "Condition");
  const groupedConditions = groupConditions(conditions);
  return {
    columnDefs,
    rowData: getConditionRowData({ conditions: groupedConditions, tableFilters }),
  };
};

export function groupConditions(conditions: Condition[]): GroupedConditions[] {
  const results: GroupedConditions[] = [];
  const conditionMap: {
    [k: string]: {
      rawCondition: Condition;
      start: string | undefined;
      end: string | undefined;
      status: string | undefined;
    }[];
  } = {};
  conditions.map(c => {
    let title: string;
    const codings = getValidCode(c.code?.coding);
    const displays = codings.map(coding => coding.display);
    const text = c.code?.text;
    if (displays.length) {
      title = Array.from(new Set(displays)).join(", ");
    } else if (text) {
      title = text;
    } else {
      results.push({ title: "-", mostRecentCondition: c });
      return;
    }
    if (!c.onsetPeriod && !c.onsetDateTime) {
      results.push({ title, mostRecentCondition: c });
      return;
    }

    const conditionPoint = {
      rawCondition: c,
      start: getOnsetTime(c),
      end: c.onsetPeriod?.end ? dayjs(c.onsetPeriod.end).format(ISO_DATE) : undefined,
      status: getStatus(c),
    };
    const groupedCondition = conditionMap[title];
    if (groupedCondition) {
      groupedCondition.push(conditionPoint);
    } else {
      conditionMap[title] = [conditionPoint];
    }
  });

  Object.entries(conditionMap).map(([title, values]) => {
    const sortedOccurrences = values.sort((a, b) => {
      const dateA = a.start ? new Date(a.start).getTime() : 0;
      const dateB = b.start ? new Date(b.start).getTime() : 0;

      return dateB - dateA;
    });
    const mostRecent = sortedOccurrences[0];
    if (!mostRecent) return;
    results.push({
      title,
      mostRecentCondition: mostRecent.rawCondition,
      sortedOccurrences,
      status: mostRecent.status,
    });
  });
  return results;
}

export function getStatus(condition: Condition): string | undefined {
  return condition.clinicalStatus?.text ??
    condition.clinicalStatus?.coding?.[0]?.display ??
    condition.clinicalStatus?.coding?.[0]?.code === "55561003"
    ? "Active"
    : condition.clinicalStatus?.coding?.[0]?.code;
}

function getConditionRowData({
  conditions,
  tableFilters,
}: {
  conditions: GroupedConditions[];
  tableFilters: MrFilterSetting | undefined;
}): ConditionRowData[] {
  return conditions
    ?.map(condition => ({
      id: condition.mostRecentCondition.id ?? "-",
      condition: condition.title,
      code: getConditionCode(condition.mostRecentCondition),
      firstSeen: getEarliestSeen(condition.sortedOccurrences),
      lastSeen: getLatestSeen(condition.sortedOccurrences),
    }))
    .filter(row => filterByDate(row.firstSeen, tableFilters?.dateFilter))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

export function getConditionCode(condition: Condition): string {
  const coding = getFirstCodeSpecified(condition.code?.coding ?? [], [ICD_10_CODE, SNOMED_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : "-";
}

export function getOnsetTime(condition: Condition): string | undefined {
  const onsetDateTime = condition.onsetDateTime;
  const onsetPeriodStart = condition.onsetPeriod?.start;
  const onsetPeriodEnd = condition.onsetPeriod?.end;

  const time = onsetDateTime || onsetPeriodStart || onsetPeriodEnd;

  if (time) {
    return dayjs(time).format(ISO_DATE);
  }

  return undefined;
}

function getEarliestSeen(occurrences: ConditionOccurrence[] | undefined): string {
  const earliest = occurrences?.reduce((acc, curr) => {
    if (!acc || (curr.start && acc.start && new Date(curr.start) < new Date(acc.start))) {
      return curr;
    }
    return acc;
  }, null as ConditionOccurrence | null);

  return earliest?.start ?? "-";
}

function getLatestSeen(occurrences: ConditionOccurrence[] | undefined): string {
  const latest = occurrences?.reduce((acc, curr) => {
    if (!acc || (curr.end && acc.end && new Date(curr.end) > new Date(acc.end))) {
      return curr;
    }
    return acc;
  }, null as ConditionOccurrence | null);

  return latest?.end ?? "-";
}
