import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { PatientConsolidatedStateProvider, AnalyticsProvider } from "@metriport/shared-internal";
import { AppStateProvider } from "./components/contexts/app";

import * as Sentry from "@sentry/react";
import HomePage from "./components/home/HomePage";
import LaunchPage from "./components/ehr/athena/LaunchPage";
import PatientPage from "./components/ehr/athena/PatientPage";

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-react-router-64-data-api
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
  return (
    <main>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <AnalyticsProvider>
            <AppStateProvider>
              <PatientConsolidatedStateProvider>
                <SentryRoutes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/athena/launch" element={<LaunchPage />} />
                  <Route path="/athena/app" element={<PatientPage />} />
                </SentryRoutes>
              </PatientConsolidatedStateProvider>
            </AppStateProvider>
          </AnalyticsProvider>
        </ChakraProvider>
      </BrowserRouter>
    </main>
  );
};
