import { Organization } from "@medplum/fhirtypes";
import { Box, BoxProps, Divider } from "@chakra-ui/react";
import { GrLocation } from "react-icons/gr";
import { References } from ".";
import { MdLibraryBooks } from "react-icons/md";

export function OrganizationReference({
  title,
  organizations,
  ...restProps
}: {
  title?: string;
  organizations: Organization[];
} & BoxProps) {
  return (
    <Box {...restProps}>
      {organizations.map((organization, index) => {
        const showTitle = index === 0;

        return (
          <Box key={index}>
            <References
              title={showTitle ? title || "Organization(s)" : undefined}
              references={[
                {
                  title: "Name",
                  value: organization.name,
                  icon: MdLibraryBooks,
                },
                {
                  title: "Address",
                  value: getAddress(organization),
                  icon: GrLocation,
                },
              ]}
            />

            {index < organizations.length - 1 && <Divider my={3} />}
          </Box>
        );
      })}
    </Box>
  );
}

function getAddress(organization: Organization): string | undefined {
  const address = organization.address?.map(address => address.line?.join(" "));

  if (!address) {
    return undefined;
  }

  return address.join(", ");
}
