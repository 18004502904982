import { Text, Flex, Divider } from "@chakra-ui/react";
import { capitalize } from "lodash";

export function DetailsDisplay({ details }: { details: { [key: string]: string | undefined } }) {
  const largestKey = Object.keys(details).reduce((a, b) => (a.length > b.length ? a : b), "");

  const fixKeyWidth = largestKey.length * 2;

  return (
    <>
      {details ? (
        <>
          {Object.entries(details).map(([key, value]) => {
            const capitalizeAllWords = key
              .split(" ")
              .map(word => capitalize(word))
              .join(" ");

            return (
              <Flex flexDirection={"row"} key={key}>
                <Text
                  className="key"
                  mr={3}
                  fontSize={18}
                  fontWeight={"bold"}
                  w={`${fixKeyWidth}%`}
                >
                  {capitalizeAllWords}:
                </Text>
                <Text w={`${100 - fixKeyWidth}%`} fontSize={18}>
                  {value ?? "-"}
                </Text>
              </Flex>
            );
          })}
          <Divider my={2} />
        </>
      ) : (
        <Text>No Details</Text>
      )}
    </>
  );
}
