import { useEffect, useState } from "react";
import { Facility } from "@metriport/api-sdk";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";

type UseGetFacilities = {
  facilities: Facility[];
};

export function useGetFacilities(): UseGetFacilities {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [facilities, setFacilities] = useState<Facility[]>([]);

  useEffect(() => {
    async function getPatient() {
      try {
        const facilities = await metriportApi.listFacilities();
        setFacilities(facilities);
      } catch (err) {
        const msg = "Failed to load  facilities";
        capture.error(msg, {
          extra: { context: `facilities.get` },
        });
        toast.error({ title: msg });
      }
    }

    getPatient();
  }, []);

  return { facilities };
}
