import dayjs from "dayjs";
import { useState } from "react";
import { Actions, Features, useAnalyticsContext } from "./analytics-context";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import { getDocumentRawContents } from "../../../api/document";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";

const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();
const XML_TIMEOUT = dayjs.duration(25, "second").asMilliseconds();

export type isDownloading = Record<
  string,
  {
    type?: string;
    downloading: boolean;
  }
>;

export type OnDownloadFileParams = {
  docId: string;
  docFilename: string;
  docExtension?: string;
  conversionType?: "html" | "pdf";
};

type UseDownloadFile = {
  isDownloading: isDownloading;
  onDownloadFile: (params: OnDownloadFileParams) => Promise<void>;
};

export function useDownloadFile(): UseDownloadFile {
  const toast = useMetriportToast();
  const metriportApi = useMetriportApi();
  const Analytics = useAnalyticsContext();

  const [isDownloading, setIsDownloading] = useState<isDownloading>({});

  async function onDownloadFile({
    docId,
    docFilename,
    docExtension,
    conversionType,
  }: OnDownloadFileParams): Promise<void> {
    Analytics.emit(Actions.download, Features.document);
    setIsDownloading({
      ...isDownloading,
      [docId]: {
        downloading: true,
        type: conversionType ?? "xml",
      },
    });
    try {
      if (conversionType) {
        toast.info({
          title: "Hold tight... This might take up to 30 seconds.",
          duration: EXPLAIN_TOAST_DURATION,
        });
      }
      const resp = await metriportApi.getDocumentUrl(docFilename, conversionType);

      let skipS3Link = false;
      if (!conversionType) {
        try {
          const contents = await getDocumentRawContents(resp.url, XML_TIMEOUT);
          if (contents.includes("<?xml-stylesheet")) {
            skipS3Link = true;
            toast.info({
              title: "This XML is not viewable in the browser, proceeding with download...",
              duration: EXPLAIN_TOAST_DURATION,
            });
          }
          const contentsBlob = new Blob([contents], { type: docExtension });
          const downloadA = document.createElement("a");
          downloadA.href = URL.createObjectURL(contentsBlob);
          downloadA.download = docFilename;
          downloadA.target = "_blank";
          document.body.appendChild(downloadA);
          downloadA.click();
          document.body.removeChild(downloadA);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error.code === "ECONNABORTED") {
            toast.error({
              title:
                "The document is too large to download, please use the API to get the contents.",
              duration: EXPLAIN_TOAST_DURATION,
            });
            return;
          }
          throw error;
        }
      }
      if (!skipS3Link) {
        const a = document.createElement("a");
        a.href = resp.url;
        a.download = docFilename;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (err) {
      capture.error(err, { extra: { docId: docId, context: `patient.file.download` } });
      toast.error();
    }
    setIsDownloading({
      ...isDownloading,
      [docId]: {
        downloading: false,
      },
    });
  }

  return { onDownloadFile, isDownloading };
}
