import { Coding, Reference, ResourceType } from "@medplum/fhirtypes";
import { MetriportMedicalApi } from "@metriport/api-sdk";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { MappedConsolidatedResources } from "../../shared-logic/consolidated-context/reducer";

export const RX_NORM_CODE = "rxnorm";
export const NDC_CODE = "ndc";
export const SNOMED_CODE = "snomed";
export const ICD_10_CODE = "icd-10";
export const ICD_9CM_CODE = "icd-9cm";
export const LOINC_CODE = "loinc";
export const MEDICARE_CODE = "medicare";
export const CPT_CODE = "cpt";
export const EPIC_CODE = "114350";
export const UNK_CODE = "UNK";
export const UNKNOWN_DISPLAY = "unknown";

const knownSystems = [
  RX_NORM_CODE,
  NDC_CODE,
  SNOMED_CODE,
  ICD_10_CODE,
  ICD_9CM_CODE,
  LOINC_CODE,
  MEDICARE_CODE,
  CPT_CODE,
  EPIC_CODE,
  UNK_CODE,
  UNKNOWN_DISPLAY,
];

export function mapCodeToSystem(coding: Coding[]): { [system: string]: string } {
  const codeMap: { [system: string]: string } = {};

  for (const code of coding) {
    codeMap[code.system ?? UNK_CODE] = code.code ?? UNKNOWN_DISPLAY;
  }

  return codeMap;
}

export function getSystemDisplay(system: string): string {
  const known = knownSystems.find(knownSystem =>
    system.toLowerCase().includes(knownSystem.toLowerCase())
  );
  if (known) {
    if (known === EPIC_CODE) return "EPIC";
    return known.toUpperCase();
  }
  return system;
}

type CodeMap = {
  system: string;
  code: string;
  display: string;
};

export function getFirstCodeSpecified(
  coding: Coding[] | undefined,
  systemsList: string[]
): CodeMap | undefined {
  let specifiedCode: CodeMap | undefined = undefined;

  if (systemsList.length && coding) {
    for (const system of systemsList) {
      const obj = coding.find(coding => {
        return coding.system?.toLowerCase().includes(system) && coding.code;
      });

      if (obj) {
        specifiedCode = {
          system: system ? system.toUpperCase() : UNK_CODE,
          code: obj.code ?? UNKNOWN_DISPLAY,
          display: obj.display ?? UNKNOWN_DISPLAY,
        };
        break;
      }
    }
  }

  return specifiedCode;
}

export function getValidCode(coding: Coding[] | undefined): Coding[] {
  if (!coding) return [];

  return coding.filter(coding => {
    return (
      coding.code &&
      coding.code.toLowerCase().trim() !== UNK_CODE.toLowerCase() &&
      coding.display &&
      coding.display.toLowerCase().trim() !== UNKNOWN_DISPLAY
    );
  });
}

export function getResourcesFromBundle<Resource>(
  bundle: MappedConsolidatedResources | undefined,
  resourceType: ResourceType
): Resource[] {
  if (!bundle) {
    return [];
  }

  const resourceMap = bundle[resourceType];

  if (!resourceMap) {
    return [];
  }

  return Object.values(resourceMap) as Resource[];
}

export function getResourceFromBundle<Resource>(
  bundle: MappedConsolidatedResources | undefined,
  resourceType: ResourceType,
  resourceId: string | undefined
): Resource | undefined {
  if (!bundle || !resourceId) {
    return undefined;
  }

  const resourceMap = bundle[resourceType];

  if (!resourceMap) {
    return undefined;
  }

  return resourceMap[resourceId] as Resource;
}

export function getResourceIdFromReference(reference: string | undefined): string | undefined {
  return reference?.split("/").pop() ?? undefined;
}

export async function downloadSourceDocument(
  docFilename: string,
  metriportApi: MetriportMedicalApi
): Promise<void> {
  const resp = await metriportApi.getDocumentUrl(docFilename, "html");

  const a = document.createElement("a");
  a.href = resp.url;
  a.download = docFilename;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function filterByDate(
  date: string,
  dateFilter?: {
    from?: string;
    to?: string;
  }
) {
  if (dateFilter) {
    const isoDate = dayjs(date, ISO_DATE);
    if (dateFilter.from && isoDate.isBefore(dayjs(dateFilter.from))) {
      return false;
    }
    if (dateFilter.to && isoDate.isAfter(dayjs(dateFilter.to))) {
      return false;
    }
  }

  return true;
}

export function compare(
  a: { [key: string]: string | number | undefined },
  b: { [key: string]: string | number | undefined },
  stringFilter?: string
): number {
  const keys = Object.keys(a);
  for (const key of keys) {
    if (stringFilter) {
      const filtersArray = stringFilter.split(",");
      const aValue = a[key] !== undefined ? a[key]?.toString().toLowerCase() : "";
      const bValue = b[key] !== undefined ? b[key]?.toString().toLowerCase() : "";

      const aHasFilter = filtersArray.some(filter => {
        return aValue?.includes(filter.toLowerCase());
      });
      const bHasFilter = filtersArray.some(filter => {
        return bValue?.includes(filter.toLowerCase());
      });

      if (aHasFilter && !bHasFilter) {
        return -1;
      } else if (!aHasFilter && bHasFilter) {
        return 1;
      }
    }
  }

  return 0;
}

export function formatDate(date: string | undefined) {
  return dayjs(date).format(ISO_DATE);
}

export function getReferenceResources<Resource>(
  references: Reference[] | undefined,
  resourceType: ResourceType,
  bundle: MappedConsolidatedResources | undefined
): Resource[] | undefined {
  if (!references || !bundle) {
    return undefined;
  }

  const mappedReferences = references.reduce((acc, reference) => {
    const resource = getReferenceResource<Resource>(reference, resourceType, bundle);

    if (resource) {
      acc.push(resource);
    }
    return acc;
  }, [] as Resource[]);

  if (!mappedReferences.length) {
    return undefined;
  }

  return mappedReferences;
}

export function getReferenceResource<Resource>(
  reference: Reference | undefined,
  resourceType: ResourceType,
  bundle: MappedConsolidatedResources | undefined
): Resource | undefined {
  if (!reference || !bundle) {
    return undefined;
  }

  const resourceId = reference.reference?.split("/")[1];

  if (!resourceId) {
    return undefined;
  }

  return getResourceFromBundle<Resource>(bundle, resourceType, resourceId);
}

export function getKnownTitle(str: string | undefined): string | undefined {
  if (!isUnknown(str)) return str;
  return undefined;
}

export function isUnknown(str: string | undefined): boolean {
  return str?.toLowerCase().trim() === "unknown";
}
