import {
  Condition,
  DiagnosticReport,
  Encounter,
  Location,
  Organization,
  Practitioner,
} from "@medplum/fhirtypes";
import { ConditionReferences } from "../../side-panel/references/condition";
import { EncounterReference } from "../../side-panel/references/encounter";
import { LocationReference } from "../../side-panel/references/location";
import { OrganizationReference } from "../../side-panel/references/organization";
import { PractitionerReference } from "../../side-panel/references/practitioner";
import { SidePanelSection } from "./sidepanel-section";
import { ReportsReferences } from "../../side-panel/references/report";

export function ReferencesDisplay({
  practitioners,
  organizations,
  encounters,
  conditions,
  locations,
  reports,
}: {
  practitioners?: {
    title?: string;
    refs?: Practitioner[] | undefined;
  };
  organizations?: {
    title?: string;
    refs?: Organization[] | undefined;
  };
  encounters?: {
    title?: string;
    refs?: Encounter[] | undefined;
  };
  conditions?: {
    title?: string;
    refs?: Condition[] | undefined;
  };
  locations?: {
    title?: string;
    refs?: Location[] | undefined;
  };
  reports?: {
    title?: string;
    refs?: DiagnosticReport[] | undefined;
  };
}) {
  const referenceItems = [
    encounters?.refs?.length && (
      <EncounterReference
        key="encounters"
        title={encounters?.title}
        mb={4}
        encounters={encounters?.refs}
      />
    ),
    locations?.refs?.length && (
      <LocationReference
        key="locations"
        title={locations?.title}
        mb={4}
        locations={locations?.refs}
      />
    ),
    practitioners?.refs?.length && (
      <PractitionerReference
        key="practitioners"
        title={practitioners?.title}
        mb={4}
        practitioners={practitioners?.refs}
      />
    ),
    organizations?.refs?.length && (
      <OrganizationReference
        key="organizations"
        title={organizations?.title}
        mb={4}
        organizations={organizations?.refs}
      />
    ),
    conditions?.refs?.length && (
      <ConditionReferences
        key="conditions"
        title={conditions?.title}
        conditions={conditions?.refs}
      />
    ),
    reports?.refs?.length && (
      <ReportsReferences key="reports" title={reports?.title} reports={reports?.refs} />
    ),
  ].filter(Boolean);

  if (referenceItems.length === 0) {
    return null;
  }

  return <SidePanelSection title="References">{referenceItems}</SidePanelSection>;
}
