import { RelatedPerson } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { RelatedPersonRowData } from "./table-data";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";

export function RelatedPersonContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & RelatedPersonRowData) {
  const selectedRelatedPerson = mappedConsolidated?.["RelatedPerson"]?.[rowContents.id] as
    | RelatedPerson
    | undefined;

  const valueString =
    selectedRelatedPerson?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Related Person"
      sourceDocument={{
        id: selectedRelatedPerson?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedRelatedPerson, null, 2) : undefined}
    >
      <RelatedPersonDisplay relatedPerson={selectedRelatedPerson} tableRow={rowContents} />
    </SidePanelContent>
  );
}

function RelatedPersonDisplay({
  relatedPerson,
  tableRow,
}: {
  relatedPerson: RelatedPerson | undefined;
  tableRow: RelatedPersonRowData;
}) {
  if (!relatedPerson) {
    return null;
  }

  return (
    <>
      <DetailsDisplay
        details={{
          name: tableRow.name,
          relationships: tableRow.relationships,
          contacts: tableRow.contacts,
          addresses: tableRow.addresses,
          birthDate: relatedPerson.birthDate,
        }}
      />
      <CodesDisplay code={relatedPerson?.relationship} />
    </>
  );
}
