import { DiagnosticReport, Observation } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import {
  compare,
  filterByDate,
  formatDate,
  getResourceIdFromReference,
  getResourcesFromBundle,
  getValidCode,
} from "../shared";
import {
  DetailedReportRowData,
  GroupedLabs,
  GroupedObservation,
  LabRowData,
  filterOutCaseReports,
  getColorForInterpretation,
  getLabReports,
  getLabs,
  getLabsDate,
  getLabsDisplay,
  getRenderValue,
  getReportName,
  getValueAndInterpretation,
  renderLabReferenceRange,
} from "./shared";

export function labTableData({ bundle, tableFilters }: GenerateTableDataParams): {
  mainColumnDefs: ColDef[];
  mainRowData: DetailedReportRowData[];
  secondaryColumnDefs: ColDef[];
  secondaryRowData: Map<string, LabRowData[]>;
  groupedRowData: LabRowData[];
} {
  const diagnosticReports = getResourcesFromBundle<DiagnosticReport>(bundle, "DiagnosticReport");
  const labReports = getLabReports(diagnosticReports);

  const observations = getResourcesFromBundle<Observation>(bundle, "Observation");
  const labObservations = getLabs(observations);
  const labsNotCaseReports = filterOutCaseReports(labObservations);

  const mainColumnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "name" },
    { field: "date" },
  ];

  const secondaryColumnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "observation" },
    {
      field: "value",
      cellStyle: params => {
        return params.data?.rowColor ? { backgroundColor: params.data.rowColor } : undefined;
      },
    },
    { field: "interpretation" },
    { field: "referenceRange" },
    {
      field: "date",
      sort: tableFilters?.stringFilter ? undefined : "desc",
    },
  ];

  const groupedLabs = groupLabs(labsNotCaseReports);

  const detailedReports: DetailedReportRowData[] = labReports
    .flatMap(report => {
      const resultRefs = report.result;
      const results = labsNotCaseReports.filter(obs =>
        resultRefs?.some(ref => getResourceIdFromReference(ref.reference) === obs.id)
      );

      // TODO: maybe keep the report if there's overview data that's useful
      if (!results.length) return [];

      const reportDate = report.effectiveDateTime ?? report.effectivePeriod?.start;
      return {
        id: report.id ?? "-",
        name: getReportName(report) ?? "Unknown panel",
        date: dayjs(reportDate).format(ISO_DATE),
        rawReport: report,
        results,
      };
    })
    .sort((a, b) => (new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1));

  const rowData = getLabsRowData({ detailedReports, tableFilters });

  return {
    mainColumnDefs,
    mainRowData: detailedReports,
    secondaryColumnDefs,
    secondaryRowData: rowData,
    groupedRowData: getGroupedLabsRowData({ labs: groupedLabs, tableFilters }),
  };
}

function getLabsRowData({
  detailedReports,
  tableFilters,
}: {
  detailedReports: DetailedReportRowData[];
  tableFilters: MrFilterSetting | undefined;
}): Map<string, LabRowData[]> {
  const rowsMap = new Map<string, LabRowData[]>();

  detailedReports.map(report =>
    report.results?.map(obs => {
      const { value, unit, interpretation, referenceRange } = getValueAndInterpretation(obs);

      const newRow: LabRowData = {
        id: obs.id ?? "-",
        observation: getLabsDisplay(obs),
        date: report.date,
        value: getRenderValue(value, unit),
        interpretation: interpretation ?? "-",
        referenceRange: renderLabReferenceRange(referenceRange, unit),
        rowColor: getColorForInterpretation(interpretation),
      };

      if (rowsMap.has(report.id)) {
        const existing = rowsMap.get(report.id);
        if (existing) {
          rowsMap.set(report.id, [...existing, newRow]);
        }
      } else {
        rowsMap.set(report.id, [newRow]);
      }
    })
  );

  rowsMap.forEach((rows, reportId) => {
    const filteredRows = rows.filter(row => filterByDate(row.date, tableFilters?.dateFilter));
    filteredRows.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    rowsMap.set(reportId, filteredRows);
  });

  return rowsMap;
}

export function groupLabs(labs: Observation[]): GroupedLabs[] {
  const results: GroupedLabs[] = [];
  const observationMap = new Map<string, GroupedObservation[]>();

  labs.map(obs => {
    let title: string;
    const codings = getValidCode(obs.code?.coding);
    const displays = codings.map(coding => coding.display);
    if (displays.length) {
      title = Array.from(new Set(displays)).join(", ");
    } else if (obs.code?.text) {
      title = obs.code.text;
      if (title.toLowerCase().includes("no data") || title.toLowerCase().includes("none recorded"))
        return;
    } else {
      results.push({ title: "-", mostRecentObservation: obs });
      return;
    }

    const {
      value: labValue,
      unit,
      interpretation,
      referenceRange,
    } = getValueAndInterpretation(obs);
    if (!obs.effectiveDateTime || (!labValue && !interpretation)) {
      return;
    }

    const observationPoint: GroupedObservation = {
      id: obs.id ?? "-",
      date: formatDate(obs.effectiveDateTime),
      unit,
      value: getRenderValue(labValue, unit),
      numericValue:
        typeof labValue === "number" ? labValue : labValue ? parseFloat(labValue) : undefined,
      interpretation: interpretation ?? "-",
      referenceRange: renderLabReferenceRange(referenceRange, unit),
      rawLabObs: obs,
    };

    const groupedObservation = observationMap.get(title);
    if (groupedObservation) {
      observationMap.set(title, [...groupedObservation, observationPoint]);
    } else {
      observationMap.set(title, [observationPoint]);
    }
  });

  Array.from(observationMap.entries()).map(([title, values]) => {
    const sortedPoints = values.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    const mostRecent = sortedPoints[sortedPoints.length - 1];
    if (!mostRecent) return;
    results.push({
      title,
      mostRecentObservation: mostRecent.rawLabObs,
      sortedPoints: sortedPoints.map(p => ({
        id: p.id,
        value: p.value,
        numericValue: p.numericValue,
        date: p.date,
        unit: p.unit,
        referenceRange: p.referenceRange,
        interpretation: p.interpretation,
      })),
    });
  });

  return results;
}

function getGroupedLabsRowData({
  labs,
  tableFilters,
}: {
  labs: GroupedLabs[];
  tableFilters: MrFilterSetting | undefined;
}): LabRowData[] {
  return labs
    ?.map(lab => {
      const mostRecentPoint = lab.sortedPoints?.[0];
      return {
        id: lab.mostRecentObservation.id ?? "-",
        observation: lab.title,
        date: mostRecentPoint?.date ?? "-",
        value: mostRecentPoint?.value ?? "-",
        interpretation: mostRecentPoint?.interpretation ?? "-",
        referenceRange: mostRecentPoint?.referenceRange ?? "-",
        mostRecentValue: mostRecentPoint?.value ?? "-",
        mostRecentDate: getLabsDate(lab.mostRecentObservation),
        rowColor: getColorForInterpretation(mostRecentPoint?.interpretation),
      };
    })
    .filter(row => filterByDate(row.mostRecentDate, tableFilters?.dateFilter))
    .sort((a, b) => {
      return compare(a, b, tableFilters?.stringFilter);
    });
}
