import { Box, Divider, Text } from "@chakra-ui/react";
import { CodeableConcept } from "@medplum/fhirtypes";
import { toArray } from "@metriport/shared";
import { getSystemDisplay, isUnknown } from "../shared";
import { SidePanelSection } from "./sidepanel-section";

export function CodesDisplay({ code }: { code: CodeableConcept[] | CodeableConcept | undefined }) {
  if (!code) {
    return null;
  }

  const codeableConcepts = toArray(code);
  const hasTexts = codeableConcepts.some(c => c.text);
  const hasValidCodes = codeableConcepts.some(c =>
    c.coding?.some(c => c.system && (c.code || c.display))
  );

  if (!hasValidCodes && !hasTexts) {
    return null;
  }

  return (
    <SidePanelSection title="Codes">
      {codeableConcepts.map((c, i) => {
        const validCodes = c.coding?.filter(c => c.system && (c.code || c.display)) ?? [];

        return (
          <Box mb={i < codeableConcepts.length - 1 ? 4 : 0} key={i}>
            {c.text && !isUnknown(c.text) && (
              <Box mb={2} flexDirection={"row"}>
                <Text fontSize={"lg"} fontWeight="bold">
                  Text:
                </Text>
                <Text>{c.text}</Text>
              </Box>
            )}
            {validCodes.length > 0 && (
              <Box>
                <Text fontSize={"lg"} fontWeight="bold">
                  Codes:
                </Text>
                {validCodes.map((c, i) => (
                  <>
                    <Box key={i}>
                      {c.system && <Text>System: {getSystemDisplay(c.system)}</Text>}
                      {c.code && <Text>Code: {c.code}</Text>}
                      {c.display && <Text>Display: {c.display}</Text>}
                    </Box>
                    {i < validCodes.length - 1 && <Divider my={2} />}
                  </>
                ))}
              </Box>
            )}
          </Box>
        );
      })}
    </SidePanelSection>
  );
}
