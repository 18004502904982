import { Observation, Organization, Practitioner } from "@medplum/fhirtypes";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { formatDate, getReferenceResources, getResourcesFromBundle } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { OccurenceDisplayText } from "../shared/occurrences";
import { showPeriodDate } from "../shared/period";
import { cloneDeep } from "lodash";
import { ReferencesDisplay } from "../shared/references";
import { SidePanelSection } from "../shared/sidepanel-section";
import { VitalsChart } from "../vitals/chart";
import { GroupedLabs, LabRowData, getLabs } from "./shared";
import { groupLabs } from "./table-data";

const CHART_DATAPOINTS = 5;

export function LabContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & LabRowData) {
  const observations = getResourcesFromBundle<Observation>(mappedConsolidated, "Observation");
  const labs = getLabs(observations);
  const groupedLabs = groupLabs(labs);
  const selectedLab =
    groupedLabs.find(lab => lab.mostRecentObservation.id === rowContents.id) ??
    groupedLabs.find(lab => lab.sortedPoints?.some(p => p.id === rowContents.id));

  if (!selectedLab) {
    return null;
  }

  const mostRecentLab = selectedLab.mostRecentObservation;
  const valueString = mostRecentLab?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  let labObservations: number[] | undefined = undefined;
  let dates: string[] | undefined = undefined;
  if (selectedLab.sortedPoints) {
    const labPointsDescOrder = cloneDeep(selectedLab.sortedPoints).reverse();
    labObservations = labPointsDescOrder.flatMap(p => p.numericValue || []);
    dates = labPointsDescOrder.map(p => p.date);
  }

  return (
    <SidePanelContent
      title="Lab"
      sourceDocument={{
        id: selectedLab.mostRecentObservation.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(mostRecentLab, null, 2) : undefined}
    >
      {labObservations && labObservations.length > 1 && dates && (
        <>
          <VitalsChart
            vitalsId={mostRecentLab.id ?? ""}
            vitals={{
              title: selectedLab.title,
              values: labObservations.slice(
                Math.max(0, labObservations.length - CHART_DATAPOINTS),
                labObservations.length
              ),
            }}
            dates={dates
              .slice(Math.max(0, dates.length - CHART_DATAPOINTS), dates.length)
              .map(formatDate)}
          />
        </>
      )}
      <LabDisplay
        lab={selectedLab}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function LabDisplay({
  lab,
  tableRow,
  mappedConsolidated,
}: {
  lab: GroupedLabs | undefined;
  tableRow: LabRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!lab) {
    return null;
  }

  const performerOrgReferences = getReferenceResources<Organization>(
    lab.mostRecentObservation.performer,
    "Organization",
    mappedConsolidated
  );

  const performerPractitionerReferences = getReferenceResources<Practitioner>(
    lab.mostRecentObservation.performer,
    "Practitioner",
    mappedConsolidated
  );
  const pointsDescOrder = [...(lab.sortedPoints || [])];

  return (
    <>
      <DetailsDisplay
        details={{
          observation: tableRow.observation,
          interpretation: tableRow.interpretation,
          "Reference Range": tableRow.referenceRange,
          period: showPeriodDate({
            period: lab.mostRecentObservation.effectivePeriod,
            fallbackDate: lab.mostRecentObservation.effectiveDateTime,
          }),
        }}
      />
      {pointsDescOrder && (
        <>
          <SidePanelSection title="Data Points">
            {pointsDescOrder
              .slice(0, 10)
              .map((p, index) => OccurenceDisplayText(p.date, p.value.toString(), index))}
          </SidePanelSection>
        </>
      )}
      <CodesDisplay code={lab.mostRecentObservation.code} />
      <ReferencesDisplay
        organizations={{
          refs: performerOrgReferences,
        }}
        practitioners={{
          refs: performerPractitionerReferences,
        }}
      />
    </>
  );
}
