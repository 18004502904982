import { ColDef } from "ag-grid-community";
import { ISO_DATE } from "@metriport/shared/common/date";
import {
  Medication,
  MedicationAdministration,
  MedicationDispense,
  MedicationStatement,
  MedicationRequest,
} from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { MrFilterSetting } from "../../../../../api/settings";
import { getFirstCodeSpecified, RX_NORM_CODE, NDC_CODE, compare, filterByDate } from "../shared";
import { GenerateTableDataParams } from "..";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { getResourcesFromBundle } from "../shared";
import { isProdEnv } from "../../../../../shared/util";

export type MedicationRowData = {
  raw: MedicationWithRefs;
  id: string;
  medication: string;
  dose: string;
  code: string;
  date: string;
  ehr?: string;
};

export type MedicationWithRefs = {
  medication: Medication;
  administration: MedicationAdministration[];
  dispense: MedicationDispense[];
  statement: MedicationStatement[];
  requests: MedicationRequest[];
};

export const medicationTableData = ({
  bundle,
  tableFilters,
  ehrActions,
}: GenerateTableDataParams) => {
  const columnDefs: ColDef<MedicationRowData>[] = [
    { field: "raw", hide: true },
    { field: "id", hide: true },
    { field: "medication" },
    { field: "dose" },
    { field: "code" },
    { field: "date", sort: tableFilters?.stringFilter ? undefined : "desc" },
  ];

  if (ehrActions && !isProdEnv()) {
    columnDefs.push({
      field: "ehr",
      onCellClicked: async event => {
        if (event.data) {
          ehrActions.setWriting(true);
          await ehrActions.write({
            patientId: ehrActions.ehrPatientId,
            resource: event.data.raw,
            path: "medication",
          });
          ehrActions.setWriting(false);
        }
      },
    });
  }

  const medications = getMedications(bundle);

  return {
    columnDefs,
    rowData: getMedicationRowData({ medications, tableFilters }),
  };
};

function getMedications(bundle: MappedConsolidatedResources | undefined): MedicationWithRefs[] {
  const medicationsWithRefs: MedicationWithRefs[] = [];

  const medications = getResourcesFromBundle<Medication>(bundle, "Medication");
  const medicationAdministration: MedicationAdministration[] =
    getResourcesFromBundle<MedicationAdministration>(bundle, "MedicationAdministration");
  const medicationDispense: MedicationDispense[] = getResourcesFromBundle<MedicationDispense>(
    bundle,
    "MedicationDispense"
  );
  const medicationStatement: MedicationStatement[] = getResourcesFromBundle<MedicationStatement>(
    bundle,
    "MedicationStatement"
  );
  const medicationRequest: MedicationRequest[] = getResourcesFromBundle<MedicationRequest>(
    bundle,
    "MedicationRequest"
  );

  for (const medication of medications) {
    const medicationWithRefs = getMedicationWithRefs(
      medication,
      medicationAdministration,
      medicationDispense,
      medicationStatement,
      medicationRequest
    );

    medicationsWithRefs.push(medicationWithRefs);
  }

  return medicationsWithRefs;
}

export function getMedicationWithRefs(
  medication: Medication,
  medicationAdministrations: MedicationAdministration[],
  medicationDispenses: MedicationDispense[],
  medicationStatements: MedicationStatement[],
  medicationRequests: MedicationRequest[]
): MedicationWithRefs {
  const medicationWithRef: MedicationWithRefs = {
    medication,
    administration: [],
    dispense: [],
    statement: [],
    requests: [],
  };

  for (const medicationTypes of [
    ...medicationAdministrations,
    ...medicationDispenses,
    ...medicationStatements,
    ...medicationRequests,
  ]) {
    const medRefId = getMedicationReferenceId(medicationTypes) || "";

    if (medRefId === medication.id) {
      if (medicationTypes.resourceType === "MedicationAdministration") {
        medicationWithRef.administration.push(medicationTypes);
      } else if (medicationTypes.resourceType === "MedicationDispense") {
        medicationWithRef.dispense.push(medicationTypes);
      } else if (medicationTypes.resourceType === "MedicationStatement") {
        medicationWithRef.statement.push(medicationTypes);
      } else if (medicationTypes.resourceType === "MedicationRequest") {
        medicationWithRef.requests.push(medicationTypes);
      }
    }
  }

  return medicationWithRef;
}

export function getMedicationReferenceId(
  medication:
    | MedicationAdministration
    | MedicationDispense
    | MedicationStatement
    | MedicationRequest
): string | undefined {
  if (medication.medicationReference?.reference) {
    return medication.medicationReference.reference.split("/")[1];
  }

  return undefined;
}

function getMedicationRowData({
  medications,
  tableFilters,
}: {
  medications: MedicationWithRefs[];
  tableFilters: MrFilterSetting | undefined;
}): MedicationRowData[] {
  return medications
    ?.map(medicationWithRefs => ({
      raw: medicationWithRefs,
      id: medicationWithRefs.medication.id ?? "-",
      medication: medicationWithRefs.medication.code?.text ?? "-",
      dose: getMedicationDose(medicationWithRefs),
      code: getMedicationCode(medicationWithRefs),
      date: getStartDate(medicationWithRefs),
    }))
    .filter(row => filterByDate(row.date, tableFilters?.dateFilter))
    .sort((a, b) =>
      compare({ ...a, raw: undefined }, { ...b, raw: undefined }, tableFilters?.stringFilter)
    )
    .reduce((acc, curr) => {
      const existing = acc.find(
        row => row.medication === curr.medication && row.dose === curr.dose
      );
      if (existing) {
        if (dayjs(curr.date).isAfter(dayjs(existing.date))) {
          return acc.map(row => (row.medication === curr.medication ? curr : row));
        }
        return acc;
      }
      return [...acc, curr];
    }, [] as MedicationRowData[]);
}

export function getMedicationDose(medicationWithRefs: MedicationWithRefs): string {
  const latestAdministered = getLatestAdministered(medicationWithRefs.administration);

  const adminDosage = latestAdministered?.dosage;
  const hasValidDosage = adminDosage?.dose?.value && adminDosage?.dose?.unit;
  const adminInstructions = hasValidDosage
    ? `${adminDosage?.dose?.value} ${adminDosage?.dose?.unit}`
    : undefined;

  return adminInstructions ?? "-";
}

function getMedicationCode(medicationWithRefs: MedicationWithRefs): string {
  const coding = getFirstCodeSpecified(medicationWithRefs.medication?.code?.coding ?? [], [
    RX_NORM_CODE,
    NDC_CODE,
  ]);

  if (coding) {
    return `${coding.system}: ${coding.code}`;
  }

  return "-";
}

function getStartDate(medicationWithRefs: MedicationWithRefs): string {
  const latestAdministered = getLatestAdministered(medicationWithRefs.administration);

  const onsetDateTime = latestAdministered?.effectiveDateTime;
  const onsetPeriodStart = latestAdministered?.effectivePeriod?.start;
  const onsetPeriodEnd = latestAdministered?.effectivePeriod?.end;

  const time = onsetDateTime || onsetPeriodStart || onsetPeriodEnd;

  if (time) {
    return dayjs(time).format(ISO_DATE);
  }

  return "-";
}

export function getLatestAdministered(
  administrations: MedicationAdministration[]
): MedicationAdministration | undefined {
  return administrations.sort((a, b) => {
    const aTime = a.effectiveDateTime || a.effectivePeriod?.start || a.effectivePeriod?.end;
    const bTime = b.effectiveDateTime || b.effectivePeriod?.start || b.effectivePeriod?.end;

    return dayjs(bTime).diff(dayjs(aTime));
  })[0];
}
