import {
  MedicationAdministration,
  MedicationDispense,
  MedicationStatement,
  MedicationRequest,
} from "@medplum/fhirtypes";
import {
  Step,
  StepDescription,
  StepIndicator,
  StepSeparator,
  StepTitle,
  Stepper,
  Box,
  useColorModeValue,
  Tag,
  Text,
} from "@chakra-ui/react";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";
import { MedicationWithRefs } from "./table-data";
import { METRIPORT_PRIMARY } from "../../../shared-logic/style";
import { References } from "../../side-panel/references";
import { GrStatusUnknown } from "react-icons/gr";
import { RiRouteLine } from "react-icons/ri";
import { GiOverdose } from "react-icons/gi";
import { TiClipboard } from "react-icons/ti";

export function MedicationHistory({
  medicationWithRef,
}: {
  medicationWithRef: MedicationWithRefs;
}) {
  const steps = [
    { title: "First", description: "Contact Info" },
    { title: "Second", description: "Date & Time" },
    { title: "Third", description: "Select Rooms" },
  ];

  const orderedMedicationRefs = orderAndGroupMedicationRefs(medicationWithRef);

  const orderedMedicationRefsSections = orderedMedicationRefs
    .filter(filterMedicationRefs)
    .map(medicationRef => {
      return medicationRef.resourceType === "MedicationStatement" ? (
        <MedicationStatementStep medicationStatement={medicationRef} />
      ) : medicationRef.resourceType === "MedicationDispense" ? (
        <MedicationDispenseStep medicationDispense={medicationRef} />
      ) : medicationRef.resourceType === "MedicationAdministration" ? (
        <MedicationAdministrationStep medicationAdministration={medicationRef} />
      ) : medicationRef.resourceType === "MedicationRequest" ? (
        <MedicationRequestStep medicationRequest={medicationRef} />
      ) : null;
    });

  if (orderedMedicationRefsSections.length === 0) {
    return <Text>No Medication History</Text>;
  }

  return (
    <Stepper orientation="vertical" index={steps.length} height="400px" gap="0">
      {orderedMedicationRefsSections}
    </Stepper>
  );
}

function MedicationStatementStep({
  medicationStatement,
}: {
  medicationStatement: MedicationStatement;
}) {
  const date =
    medicationStatement.dateAsserted ??
    medicationStatement.effectivePeriod?.start ??
    medicationStatement.effectiveDateTime ??
    medicationStatement.effectivePeriod?.end;

  return (
    <MedicationStep title="Medication Documented" date={dayjs(date).format(ISO_DATE)}>
      <References
        templateColumns="repeat(1, 1fr)"
        references={[
          {
            title: "Instructions",
            value: medicationStatement.dosage?.[0]?.text,
            icon: TiClipboard,
          },
          {
            title: "Status",
            value: (
              <Tag
                size="sm"
                variant="subtle"
                colorScheme={setStatusCodeColor(medicationStatement.status)}
              >
                {medicationStatement.status}
              </Tag>
            ),
            icon: GrStatusUnknown,
          },
        ]}
      />
    </MedicationStep>
  );
}

function MedicationDispenseStep({
  medicationDispense,
}: {
  medicationDispense: MedicationDispense;
}) {
  const date = medicationDispense.whenHandedOver;

  return (
    <MedicationStep title="Medication Dispensed" date={dayjs(date).format(ISO_DATE)}>
      <References
        references={[
          {
            title: "Dosage Quantity",
            value: `${medicationDispense.quantity?.value} ${medicationDispense.quantity?.unit}`,
            icon: GiOverdose,
          },
          {
            title: "Status",
            value: (
              <Tag
                size="sm"
                variant="subtle"
                colorScheme={setStatusCodeColor(medicationDispense.status)}
              >
                {medicationDispense.status}
              </Tag>
            ),
            icon: GrStatusUnknown,
          },
        ]}
      />
    </MedicationStep>
  );
}

function MedicationAdministrationStep({
  medicationAdministration,
}: {
  medicationAdministration: MedicationAdministration;
}) {
  const date =
    medicationAdministration.effectivePeriod?.start ??
    medicationAdministration.effectiveDateTime ??
    medicationAdministration.effectivePeriod?.end;

  const hasDosage =
    medicationAdministration.dosage?.dose?.value && medicationAdministration.dosage?.dose?.unit;

  return (
    <MedicationStep
      title="Medication Administered"
      date={dayjs(date).format(`${ISO_DATE} hh:mm:ss A UTC`)}
    >
      <References
        references={[
          {
            title: "Dosage Route",
            value: medicationAdministration.dosage?.route?.text,
            icon: RiRouteLine,
          },
          {
            title: "Dosage Rate",
            value: hasDosage
              ? `${medicationAdministration.dosage?.dose?.value} ${medicationAdministration.dosage?.dose?.unit}`
              : undefined,
            icon: GiOverdose,
          },
          {
            title: "Status",
            value: (
              <Tag
                size="sm"
                variant="subtle"
                colorScheme={setStatusCodeColor(medicationAdministration.status)}
              >
                {medicationAdministration.status}
              </Tag>
            ),
            icon: GrStatusUnknown,
          },
        ]}
      />
    </MedicationStep>
  );
}

function MedicationRequestStep({ medicationRequest }: { medicationRequest: MedicationRequest }) {
  const date = medicationRequest.authoredOn;

  return (
    <MedicationStep title="Medication Requested" date={dayjs(date).format(ISO_DATE)}>
      <References
        references={[
          {
            title: "Note",
            value: medicationRequest.note?.[0]?.text,
            icon: TiClipboard,
          },
          {
            title: "Status",
            value: (
              <Tag
                size="sm"
                variant="subtle"
                colorScheme={setStatusCodeColor(medicationRequest.status)}
              >
                {medicationRequest.status}
              </Tag>
            ),
            icon: GrStatusUnknown,
          },
        ]}
      />
    </MedicationStep>
  );
}

function MedicationStep({
  title,
  date,
  children,
}: {
  title: string;
  date: string;
  children: React.ReactNode;
}) {
  return (
    <Step style={{ width: "100%" }}>
      <StepIndicator style={{ background: METRIPORT_PRIMARY }} />

      <Box w={"100%"}>
        <Box mb={2}>
          <StepTitle>{title}</StepTitle>
          <StepDescription>{date}</StepDescription>
        </Box>
        <Box
          w={"100%"}
          mb={4}
          px={4}
          py={2}
          border="solid"
          borderRadius={10}
          borderColor={useColorModeValue("gray.200", "gray.700")}
        >
          {children}
        </Box>
      </Box>

      <StepSeparator style={{ background: METRIPORT_PRIMARY }} />
    </Step>
  );
}

function orderAndGroupMedicationRefs(medicationWithRefs: MedicationWithRefs) {
  const { administration, dispense, statement, requests } = medicationWithRefs;
  const allMedicationRefs = [...administration, ...dispense, ...statement, ...requests];

  const orderedMedicationRefs = allMedicationRefs.sort((a, b) => {
    const dateA = getDateFromMedicationRef(a);
    const dateB = getDateFromMedicationRef(b);

    if (!dateA || !dateB) {
      return 0;
    }

    return new Date(dateB).getTime() - new Date(dateA).getTime();
  });

  return orderedMedicationRefs;
}

const getDateFromMedicationRef = (
  medicationRef:
    | MedicationAdministration
    | MedicationDispense
    | MedicationStatement
    | MedicationRequest
) => {
  let date;
  if (medicationRef.resourceType === "MedicationAdministration") {
    date =
      medicationRef.effectivePeriod?.start ??
      medicationRef.effectiveDateTime ??
      medicationRef.effectivePeriod?.end;
  } else if (medicationRef.resourceType === "MedicationDispense") {
    date = medicationRef.whenHandedOver;
  } else if (medicationRef.resourceType === "MedicationStatement") {
    date =
      medicationRef.dateAsserted ??
      medicationRef.effectivePeriod?.start ??
      medicationRef.effectiveDateTime ??
      medicationRef.effectivePeriod?.end;
  } else if (medicationRef.resourceType === "MedicationRequest") {
    date = medicationRef.authoredOn;
  }

  if (date) {
    return dayjs(date).format(ISO_DATE);
  }

  return undefined;
};

function filterMedicationRefs(
  medicationWithRef:
    | MedicationAdministration
    | MedicationDispense
    | MedicationStatement
    | MedicationRequest
): boolean {
  if (medicationWithRef.resourceType === "MedicationAdministration") {
    return !!getDateFromMedicationRef(medicationWithRef);
  } else if (medicationWithRef.resourceType === "MedicationDispense") {
    return !!getDateFromMedicationRef(medicationWithRef);
  } else if (medicationWithRef.resourceType === "MedicationStatement") {
    return !!getDateFromMedicationRef(medicationWithRef);
  } else if (medicationWithRef.resourceType === "MedicationRequest") {
    const hasDate = !!getDateFromMedicationRef(medicationWithRef);

    return hasDate;
  }

  return false;
}

function setStatusCodeColor(status: string | undefined) {
  switch (status) {
    case "on-hold":
    case "triaged":
      return "yellow";
    case "in-progress":
    case "active":
      return "teal";
    case "completed":
      return "green";
    case "cancelled":
    case "entered-in-error":
    case "stopped":
      return "red";
    default:
      return "gray";
  }
}
