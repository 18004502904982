import { DiagnosticReport, Organization, Practitioner, Procedure } from "@medplum/fhirtypes";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { getReferenceResource } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { showPeriodDate } from "../shared/period";
import { ReferencesDisplay } from "../shared/references";
import { ProcedureRowData } from "./table-data";

export function ProcedureContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & ProcedureRowData) {
  const selectedProcedure = mappedConsolidated?.["Procedure"]?.[rowContents.id] as
    | Procedure
    | undefined;

  const valueString = selectedProcedure?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Procedure"
      sourceDocument={{
        id: selectedProcedure?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedProcedure, null, 2) : undefined}
    >
      <ProcedureDisplay
        procedure={selectedProcedure}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function ProcedureDisplay({
  procedure,
  tableRow,
  mappedConsolidated,
}: {
  procedure: Procedure | undefined;
  tableRow: ProcedureRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!procedure) {
    return null;
  }

  const practitioners: Practitioner[] = [];
  const organizations: Organization[] = [];
  const reports: DiagnosticReport[] = [];

  procedure?.performer?.forEach(perf => {
    if (perf.actor?.reference?.startsWith("Practitioner")) {
      const pract = getReferenceResource<Practitioner>(
        perf.actor,
        "Practitioner",
        mappedConsolidated
      );
      if (pract) practitioners.push(pract);
      return;
    }

    if (perf.actor?.reference?.startsWith("Organization")) {
      const org = getReferenceResource<Organization>(
        perf.actor,
        "Organization",
        mappedConsolidated
      );
      if (org) organizations.push(org);
      return;
    }
  });

  procedure?.report?.forEach(report => {
    if (report.reference?.startsWith("DiagnosticReport")) {
      const diagReport = getReferenceResource<DiagnosticReport>(
        report,
        "DiagnosticReport",
        mappedConsolidated
      );
      if (diagReport) reports.push(diagReport);
    }
  });

  return (
    <>
      <DetailsDisplay
        details={{
          procedure: tableRow.procedure,
          status: tableRow.status,
          period: showPeriodDate({
            period: procedure.performedPeriod,
            fallbackDate: procedure.performedDateTime,
          }),
        }}
      />
      <CodesDisplay code={procedure.code} />
      <ReferencesDisplay
        practitioners={{
          refs: practitioners,
        }}
        organizations={{
          refs: organizations,
        }}
        reports={{
          refs: reports,
        }}
      />
    </>
  );
}
