import { AllergyIntolerance } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate, getResourcesFromBundle } from "../shared";

export type AllergyRowData = {
  id: string;
  allergy: string;
  manifestation: string;
  firstSeen: string;
  status: string;
};

export const allergyTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<AllergyRowData>[] = [
    { field: "id", hide: true },
    { field: "allergy" },
    { field: "manifestation" },
    { field: "firstSeen", sort: tableFilters?.stringFilter ? undefined : "desc" },
    { field: "status" },
  ];

  const allergies = getResourcesFromBundle<AllergyIntolerance>(bundle, "AllergyIntolerance");

  return {
    columnDefs,
    rowData: getAllergyRowData({ allergies, tableFilters }),
  };
};

function getAllergyRowData({
  allergies,
  tableFilters,
}: {
  allergies: AllergyIntolerance[];
  tableFilters: MrFilterSetting | undefined;
}): AllergyRowData[] {
  return allergies
    ?.map(allergy => ({
      id: allergy.id ?? "-",
      allergy: getAllergyName(allergy),
      manifestation: getAllergyManifestation(allergy),
      firstSeen: getAllergyOnset(allergy),
      status: getAllergyClinicalStatus(allergy),
    }))
    .filter(row => filterByDate(row.firstSeen, tableFilters?.dateFilter))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

function getAllergyName(allergy: AllergyIntolerance): string {
  const names: string[] = [];

  for (const reaction of allergy.reaction ?? []) {
    if (reaction.substance?.text) {
      names.push(reaction.substance.text);
    }
  }

  if (names && names.length > 0) {
    return names.join(", ");
  }

  return "-";
}

function getAllergyManifestation(allergy: AllergyIntolerance): string {
  const manifestations: string[] = [];

  for (const reaction of allergy.reaction ?? []) {
    for (const manifestation of reaction.manifestation ?? []) {
      if (manifestation.text) {
        manifestations.push(manifestation.text);
      }
    }
  }

  if (manifestations && manifestations.length > 0) {
    return manifestations.join(", ");
  }

  return "-";
}

function getAllergyOnset(allergy: AllergyIntolerance): string {
  if (allergy.onsetDateTime) {
    return dayjs(allergy.onsetDateTime).format(ISO_DATE);
  } else if (allergy.recordedDate) {
    return dayjs(allergy.recordedDate).format(ISO_DATE);
  }

  return "-";
}

function getAllergyClinicalStatus(allergy: AllergyIntolerance): string {
  return allergy.clinicalStatus?.coding?.[0]?.code ?? "-";
}
