import { useEffect, useState } from "react";
import { capture } from "../../../shared/capture";
import useMetriportToast from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { getSettings, MrFilterSetting } from "../../../api/settings";

type UseGetFilters = {
  filters: MrFilterSetting[] | undefined;
};

export function useGetFilters(): UseGetFilters {
  const toast = useMetriportToast();
  const [filters, setFilters] = useState<MrFilterSetting[] | undefined>(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const settings = await getSettings();

        if (settings?.mrFilters) {
          setFilters(settings.mrFilters);
        }
      } catch (err) {
        console.log(err);
        const msg = "Failed to load mr filter settings";
        capture.error(msg, {
          extra: { context: `patient.get.settings`, err },
        });
        toast.error({ title: msg });
      }
    }

    fetchData();
  }, []);

  return { filters };
}
