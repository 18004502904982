import { ReactNode } from "react";
import { Text, Box, useColorModeValue } from "@chakra-ui/react";

export function SidePanelSection({ title, children }: { title: string; children: ReactNode }) {
  return (
    <Box mb={3}>
      <Text mb={2} fontSize={"2xl"} fontWeight="bold">
        {title}:
      </Text>
      <Box
        px={4}
        py={2}
        border="solid"
        borderRadius={10}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        {children}
      </Box>
    </Box>
  );
}
