import { Flex, Text } from "@chakra-ui/react";
import { METRIPORT_PRIMARY } from "../../button";
import { formatDate } from "../shared";

export function OccurenceDisplayPeriods(
  date1: string | undefined,
  date2: string | undefined,
  index: number
) {
  const { fontWeight, color } = getColorAndFontWeight(index);

  return (
    <Flex flexDirection={"row"}>
      <Text color={color} fontWeight={fontWeight} w={"120px"}>
        {`• ${formatDate(date1)}`}
      </Text>
      {date2 && (
        <>
          <Text color={color} fontWeight={fontWeight} w={"20px"}>
            -
          </Text>
          <Text color={color} fontWeight={fontWeight}>{`${formatDate(date2)}`}</Text>
        </>
      )}
    </Flex>
  );
}

export function OccurenceDisplayText(
  date: string | undefined,
  text: string | undefined,
  index: number
) {
  const { fontWeight, color } = getColorAndFontWeight(index);

  return (
    <Flex flexDirection={"row"}>
      <Text color={color} fontWeight={fontWeight} w={"120px"}>
        {`• ${formatDate(date)}`}
      </Text>
      {text && (
        <>
          <Text color={color} fontWeight={fontWeight} w={"20px"}>
            :{" "}
          </Text>
          <Text color={color} fontWeight={fontWeight}>{`${text}`}</Text>
        </>
      )}
    </Flex>
  );
}

function getColorAndFontWeight(index: number) {
  const fontWeight = index === 0 ? "bold" : "normal";
  const color = index === 0 ? METRIPORT_PRIMARY : undefined;
  return { fontWeight, color };
}
