import { useEffect, useState } from "react";
import { PatientDTO } from "@metriport/api-sdk";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";

type UseGetPatientInfo = {
  patient: PatientDTO | undefined;
  isFetchingPatient: boolean;
  setPatient: (patient: PatientDTO | undefined) => void;
};

export function useGetPatientInfo({ patientId }: { patientId: string }): UseGetPatientInfo {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [patient, setPatient] = useState<PatientDTO | undefined>(undefined);
  const [isFetchingPatient, setIsFetchingPatient] = useState(true);

  useEffect(() => {
    async function getPatient() {
      try {
        const patient = await metriportApi.getPatient(patientId);
        setPatient(patient);
      } catch (err) {
        const msg = "Failed to load patient data";
        capture.error(msg, {
          extra: { patient: patientId, context: `patient.get` },
        });
        toast.error({ title: msg });
      } finally {
        setIsFetchingPatient(false);
      }
    }

    getPatient();
  }, []);

  return { patient, isFetchingPatient, setPatient };
}
