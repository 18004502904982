import { Box, BoxProps, Divider } from "@chakra-ui/react";
import { DiagnosticReport } from "@medplum/fhirtypes";
import { GiMaterialsScience } from "react-icons/gi";
import { GrDocumentTime } from "react-icons/gr";
import { References } from ".";
import { limitStringLength } from "../../../../../shared/util";
import { formatDate, getValidCode } from "../../fhir-sections/shared";

export function ReportsReferences({
  title,
  reports,
  ...restProps
}: {
  title?: string;
  reports: DiagnosticReport[];
} & BoxProps) {
  return (
    <Box {...restProps}>
      {reports.map((report, index) => {
        const showTitle = index === 0;

        return (
          <Box key={index}>
            <References
              title={showTitle ? title || "DiagnosticReport(s)" : undefined}
              references={[
                {
                  title: "Name",
                  value: limitStringLength(getKnownCode(report), 40),
                  icon: GiMaterialsScience,
                },
                {
                  title: "Date",
                  value: getPeriodDate(report),
                  icon: GrDocumentTime,
                },
              ]}
            />
            {index < reports.length - 1 ? <Divider my={3} /> : ``}
          </Box>
        );
      })}
    </Box>
  );
}

function getKnownCode(report: DiagnosticReport): string | undefined {
  const codings = getValidCode(report.code?.coding);
  const displays = codings.map(coding => coding.display);
  return displays.length > 0 ? Array.from(new Set(displays)).join(", ") : report.code?.text;
}

function getPeriodDate(report: DiagnosticReport): string | undefined {
  const date = report.effectivePeriod?.start;
  return date ? formatDate(date) : undefined;
}
