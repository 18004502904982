import { useLocation, useNavigate } from "react-router-dom";
import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from "react";
import { Skeleton } from "@chakra-ui/react";
import { setAuthTokenHeaders } from "../../../api/api";
import { getMetriportPatientId as athenaGetMetriportPatientId } from "../../../api/ehr/athena";
import { ehrSchema } from "../../../domain/ehr";
import { AppStateActionType } from "./reducer";
import useMetriportToast from "../../shared/toast";
import { captureAndDisplayError } from "../../shared/util";
import { AppState, AppStateAction, initialState, reducer } from "./reducer";

interface IAppStateContext {
  state: AppState;
  dispatch: Dispatch<AppStateAction>;
}

const pathsToSkipInitialization = ["/", "/athena/launch"];

export const AppStateContext = createContext<IAppStateContext>({
  state: initialState,
  dispatch: () => null,
});

export const AppStateProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const toast = useMetriportToast();

  const path = location.pathname;
  const skipPath = pathsToSkipInitialization.includes(path);

  useEffect(() => {
    async function fetchInitialState() {
      if (skipPath) return;
      try {
        // Token
        const hash = location.hash;
        const parsedHash = new URLSearchParams(hash.substring(1));
        const token = parsedHash.get("access_token");
        navigate(path, { replace: true });
        if (!token) throw new Error("Failed to get token");
        setAuthTokenHeaders(token);
        // EHR
        const ehr = ehrSchema.parse({ rootPath: path });
        // Patient
        let athenaToMetriportId: string | undefined = undefined;
        if (ehr.rootPath === "/athena/app") {
          const patient = parsedHash.get("patient");
          if (!patient) throw new Error("Failed to get patient");
          await athenaGetMetriportPatientId(patient);
          athenaToMetriportId = patient;
        }

        dispatch({
          type: AppStateActionType.update,
          newState: {
            athenaToMetriportId,
            authToken: token,
            isLoaded: true,
          },
        });
      } catch (error) {
        captureAndDisplayError({
          error,
          msg: "Failed to initialize the application",
          context: "fetchInitialState",
          captureMsg: "Component: AppStateProvider - initializing ehr dash state failed",
          toast,
          extras: {
            path,
          },
        });
      }
    }
    fetchInitialState();
  }, []);

  // Prevent that the UI/components are rendered before set the auth token
  if (!skipPath && !state.isLoaded) return <Skeleton height="100vh" width="100vw" />;

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>
  );
};

export const useAppContext = () => useContext(AppStateContext);
