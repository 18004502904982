import { Box, Text } from "@chakra-ui/react";
import { PatientOverview, SharedStateProvider } from "@metriport/shared-internal";
import { useAppContext } from "../../contexts/app/index";
import { ossBaseURLAthena } from "../../../api/api";
import { writeResourceToChart } from "../../../api/ehr/athena";

export default function PatientPage() {
  const { state } = useAppContext();

  if (!state.isLoaded) {
    return null;
  }

  return (
    <Box>
      {state.athenaToMetriportId ? (
        <SharedStateProvider
          authToken={state.authToken}
          baseUrl={ossBaseURLAthena}
          ehrDetails={{
            ehrPatientId: state.athenaToMetriportId,
            name: "Athena",
            write: writeResourceToChart,
          }}
        >
          <PatientOverview patientId={state.athenaToMetriportId} />
        </SharedStateProvider>
      ) : (
        <Text>No Patient</Text>
      )}
    </Box>
  );
}
