import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";

export function SidePanel({
  showPanel,
  setSidePanelContent,
  children,
}: {
  showPanel: boolean;
  setSidePanelContent: () => void;
  children: React.ReactNode;
}) {
  const bg = useColorModeValue("white", "gray.800");

  const useModal = useBreakpointValue({
    base: true,
    "2xl": false,
  });

  if (useModal) {
    return (
      <Modal size={"2xl"} isOpen={showPanel} onClose={setSidePanelContent}>
        <ModalOverlay />
        <ModalContent
          overflow={"auto"}
          height={"calc(100vh - 100px)"}
          mx={8}
          bg={bg}
          borderRadius={10}
          border={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          px={4}
        >
          <Box bg={bg} position="sticky" top={0} right={0} zIndex={1}>
            <ModalActions setSidePanelContent={setSidePanelContent} />
          </Box>
          {children}
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box
      id="side-panel"
      overflow={"auto"}
      hidden={!showPanel}
      height={"calc(100vh - 100px)"}
      top={50 + 25}
      position="sticky"
      ml={6}
      bg={bg}
      width={"35vw"}
      borderRadius={10}
      border={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.700")}
      px={8}
    >
      <Box bg={bg} position="sticky" top={0} right={0} zIndex={1}>
        <ModalActions setSidePanelContent={setSidePanelContent} />
      </Box>
      {children}
    </Box>
  );
}

function ModalActions({ setSidePanelContent }: { setSidePanelContent: () => void }) {
  return (
    <Flex flexDirection={"row"} pt={2} mb={2} justifyContent={"flex-end"}>
      <IconButton
        background={"transparent"}
        aria-label="Close side panel"
        icon={<RiCloseLine />}
        onClick={setSidePanelContent}
      />
    </Flex>
  );
}
