import { Practitioner, HumanName } from "@medplum/fhirtypes";
import { Box, BoxProps, Divider } from "@chakra-ui/react";
import { RiStethoscopeLine } from "react-icons/ri";
import { MdPerson } from "react-icons/md";
import { References } from ".";

export function PractitionerReference({
  title,
  practitioners,
  ...restProps
}: {
  title?: string;
  practitioners: Practitioner[];
} & BoxProps) {
  return (
    <Box {...restProps}>
      {practitioners.map((practitioner, index) => {
        const showTitle = index === 0;

        return (
          <Box key={index}>
            <References
              title={showTitle ? title || "Practitioner(s)" : undefined}
              references={[
                {
                  title: "Name",
                  value: getName(practitioner),
                  icon: MdPerson,
                },
                {
                  title: "Qualification",
                  value: getQualification(practitioner),
                  icon: RiStethoscopeLine,
                },
              ]}
            />
            {index < practitioners.length - 1 && <Divider my={3} />}
          </Box>
        );
      })}
    </Box>
  );
}

function getName(practitioner: Practitioner): string | undefined {
  const findUsedName = practitioner.name?.find(name => name.use);
  const fullName = findUsedName?.text;
  const buildFullName = findUsedName
    ? buildName(findUsedName)
    : practitioner?.name?.[0]
    ? buildName(practitioner.name[0])
    : undefined;

  return fullName || buildFullName;
}

function buildName(name: HumanName): string {
  const firstName = name.given?.join(" ");
  const familyName = name.family || "";
  const suffix = name.suffix || "";
  return `${firstName} ${familyName} ${suffix}`;
}

function getQualification(practitioner: Practitioner): string | undefined {
  const qualifications = practitioner.qualification?.map(qualification => qualification.code?.text);

  if (!qualifications) {
    return undefined;
  }

  return qualifications?.join(", ");
}
