import { Period } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import dayjs from "dayjs";

export function showPeriodDate({
  period,
  fallbackDate,
}: {
  period: Period | undefined;
  fallbackDate?: string;
}): string {
  if (!period && !fallbackDate) {
    return fallbackDate ? `${dayjs(fallbackDate).format(ISO_DATE)}` : "No Period";
  } else if (!period) {
    return `${dayjs(fallbackDate).format(ISO_DATE)}`;
  } else {
    return `${dayjs(period.start).format(ISO_DATE)} - ${dayjs(period.end).format(ISO_DATE)}`;
  }
}
