import { Condition } from "@medplum/fhirtypes";
import { Divider, Box, BoxProps } from "@chakra-ui/react";
import { limitStringLength } from "../../../../../shared/util";
import { References } from ".";
import { getValidCode } from "../../fhir-sections/shared";
import { getConditionCode } from "../../fhir-sections/conditions/table-data";
import { GiMaterialsScience } from "react-icons/gi";
import { FiCodesandbox } from "react-icons/fi";

export function ConditionReferences({
  title,
  conditions,
  ...restProps
}: {
  title?: string;
  conditions: Condition[];
} & BoxProps) {
  return (
    <Box {...restProps}>
      {conditions.map((condition, index) => {
        const showTitle = index === 0;

        return (
          <Box key={index}>
            <References
              title={showTitle ? title || "Condition(s)" : undefined}
              references={[
                {
                  title: "Name",
                  value: limitStringLength(getConditionName(condition), 40),
                  icon: GiMaterialsScience,
                },
                {
                  title: "Code",
                  value: getConditionCode(condition),
                  icon: FiCodesandbox,
                },
              ]}
            />
            {index < conditions.length - 1 && <Divider my={3} />}
          </Box>
        );
      })}
    </Box>
  );
}

function getConditionName(condition: Condition): string | undefined {
  let title = undefined;

  const codings = getValidCode(condition.code?.coding);
  const displays = codings.map(coding => coding.display);
  const text = condition.code?.text;
  if (displays.length) {
    title = Array.from(new Set(displays)).join(", ");
  } else if (text) {
    title = text;
  }

  return title;
}
