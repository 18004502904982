import { Procedure } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate, getResourcesFromBundle } from "../shared";

export type ProcedureRowData = {
  id: string;
  procedure: string;
  datePerformed: string;
  status: string;
};

export const procedureTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<ProcedureRowData>[] = [
    { field: "id", hide: true },
    { field: "procedure" },
    { field: "datePerformed", sort: tableFilters?.stringFilter ? undefined : "desc" },
    { field: "status" },
  ];

  const procedures = getResourcesFromBundle<Procedure>(bundle, "Procedure");

  return {
    columnDefs,
    rowData: getProcedureRowData({ procedures, tableFilters }),
  };
};

function getProcedureRowData({
  procedures,
  tableFilters,
}: {
  procedures: Procedure[];
  tableFilters: MrFilterSetting | undefined;
}): ProcedureRowData[] {
  return procedures
    ?.map(procedure => ({
      id: procedure.id ?? "-",
      procedure: getProcedureName(procedure),
      datePerformed: getProcedureDatePerformed(procedure),
      status: procedure.status ?? "-",
    }))
    .filter(row => filterByDate(row.datePerformed, tableFilters?.dateFilter))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}
function getProcedureName(procedure: Procedure): string {
  const displayText = procedure.code?.coding?.flatMap(coding => coding.display || []).join(", ");
  return procedure.code?.text ?? (displayText && displayText.length ? displayText : "-");
}

function getProcedureDatePerformed(procedure: Procedure): string {
  const validDate = procedure.performedDateTime ?? procedure.performedPeriod?.start;

  if (!validDate) {
    return "-";
  }

  return dayjs(validDate).format(ISO_DATE);
}
