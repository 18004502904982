import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Bundle, Resource } from "@medplum/fhirtypes";
import useMetriportToast from "./useMetriportToast";
import { useMetriportApi } from "./useMetriportApi";
import { useInterval } from "./use-interval";
// TODO: 2064 Will add to shared when its used by monorepo
import { getConsolidatedWebhook } from "../../../api/consolidated-bundle";
import { capture } from "../../../shared/capture";

type UseGetConsolidated = {
  consolidated: Bundle<Resource> | undefined;
  isConsolidatedLoading: boolean;
};

const POLLING_INTERVAL = dayjs.duration(1, "second").asMilliseconds();
const EXPLAIN_TOAST_DURATION = dayjs.duration(5, "second").asMilliseconds();

export function useGetConsolidated({
  patientId,
  canQueryConsolidated,
}: {
  patientId: string;
  canQueryConsolidated: boolean;
}): UseGetConsolidated {
  const toast = useMetriportToast();
  const metriportApi = useMetriportApi();

  const [consolidatedRequestId, setConsolidatedRequestId] = useState<string | undefined>(undefined);
  const [isConsolidatedLoading, setIsConsolidatedLoading] = useState(false);
  const [consolidated, setConsolidated] = useState<Bundle<Resource> | undefined>(undefined);

  useEffect(() => {
    if (canQueryConsolidated) {
      setIsConsolidatedLoading(true);
      getPatientConsolidated();
    }
  }, [canQueryConsolidated]);

  async function getPatientConsolidated() {
    try {
      const query = await metriportApi.startConsolidatedQuery(
        patientId,
        undefined,
        undefined,
        undefined,
        "json",
        true
      );

      setConsolidatedRequestId(query.requestId);

      toast.info({
        title:
          "Loading the patient's consolidated data. This may take some time depending on how much data is available.",
        duration: EXPLAIN_TOAST_DURATION,
      });
    } catch (err) {
      const msg = "Failed to load patient consolidated data";
      capture.error(msg, {
        extra: { patient: patientId, context: `patient.get.consolidated`, err },
      });
      toast.error({ title: msg });
    }
  }

  useInterval(
    async () => {
      const { queries } = await metriportApi.getConsolidatedQueryStatus(patientId);
      const allDone = queries?.every(query => query.status === "completed");

      if (allDone && consolidatedRequestId) {
        const requestId = consolidatedRequestId;
        const webhook = await getConsolidatedWebhook(patientId, requestId);
        const { fileUrl } = webhook;

        if (fileUrl) {
          const bundle = await fetch(fileUrl).then(res => res.json());
          setConsolidated(bundle as Bundle<Resource>);
          setIsConsolidatedLoading(false);
          setConsolidatedRequestId(undefined);
        } else {
          setConsolidatedRequestId(undefined);
          setIsConsolidatedLoading(false);

          const msg = "Failed to load patient consolidated data";
          capture.error(msg, {
            extra: { patient: patientId, context: `patient.get.consolidated`, webhook },
          });
        }
      }
    },
    isConsolidatedLoading ? POLLING_INTERVAL : null
  );

  return { consolidated, isConsolidatedLoading };
}
