import axios from "axios";
import { isDevEnv } from "../shared/util";

export function getBaseURL(): string {
  return process.env.REACT_APP_API_URL!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
}

export const ossBaseURLAthena = `${getBaseURL()}/${isDevEnv() ? "athenahealth" : "athena"}`;
export const apiOssProxyAthena = axios.create({ baseURL: ossBaseURLAthena });

export function setAuthTokenHeaders(token: string) {
  apiOssProxyAthena.defaults.headers.common["Authorization"] = token;
}
