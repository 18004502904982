import axios from "axios";
import dayjs from "dayjs";

const DEFAULT_TIMEOUT = dayjs.duration(10, "second").asMilliseconds();

export async function getDocumentRawContents(
  docUrl: string,
  timeout: number = DEFAULT_TIMEOUT
): Promise<string> {
  const apiS3 = axios.create();
  apiS3.defaults.timeout = timeout;
  const resp = await apiS3.get(docUrl);
  return resp.data;
}
