export const envTypeEnvVarName = "REACT_APP_ENV_TYPE";
export const envTypeDev = "development";
export const envTypeStaging = "staging";
export const envTypeProd = "production";

export function getEnvType(): string {
  return getEnvVarOrFail(envTypeEnvVarName);
}
export function isDevEnv(): boolean {
  return getEnvType() === envTypeDev;
}

export function getEnvVar(varName: string): string | undefined {
  return process.env[varName];
}

export function isProdEnv(): boolean {
  return getEnvType() === envTypeProd;
}

export function isStagingEnv(): boolean {
  return getEnvType() === envTypeStaging;
}

export function getEnvVarOrFail(varName: string): string {
  const value = getEnvVar(varName);
  if (!value || value.trim().length < 1) {
    throw new Error(`Missing ${varName} env var`);
  }
  return value;
}
