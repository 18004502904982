import { ColDef } from "ag-grid-community";
import { ISO_DATE } from "@metriport/shared/common/date";
import { Immunization } from "@medplum/fhirtypes";
import dayjs from "dayjs";
import { MrFilterSetting } from "../../../../../api/settings";
import { getFirstCodeSpecified, RX_NORM_CODE, compare, filterByDate } from "../shared";
import { GenerateTableDataParams } from "..";
import { getResourcesFromBundle } from "../shared";
import { getValidCode } from "../shared";

export type ImmunizationRowData = {
  id: string;
  immunization: string;
  code: string;
  manufacturer: string;
  date: string;
  status: string;
};

type ImmunizationOccurrence = {
  rawImmunization: Immunization;
  date: string;
  status: string;
};

export type GroupedImmunizations = {
  title: string;
  mostRecentImmunization: ImmunizationOccurrence;
  code: string;
  sortedOccurrences: ImmunizationOccurrence[];
  status: string;
};

export const immunizationTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<ImmunizationRowData>[] = [
    { field: "id", hide: true },
    {
      field: "immunization",
    },
    { field: "code" },
    { field: "manufacturer" },
    { field: "date", sort: tableFilters?.stringFilter ? undefined : "desc" },
    { field: "status" },
  ];

  const immunizations = getResourcesFromBundle<Immunization>(bundle, "Immunization");
  const groupedImmunizations = groupImmunizations(immunizations);
  return {
    columnDefs,
    rowData: getImmunizationRowData({ immunizations: groupedImmunizations, tableFilters }),
  };
};

export function groupImmunizations(imms: Immunization[]): GroupedImmunizations[] {
  const results: GroupedImmunizations[] = [];
  const immsMap = new Map<string, ImmunizationOccurrence[]>();

  imms.map(imm => {
    const code = getImmunizationsCode(imm);
    const title = getImmunizationsDisplay(imm);
    const date = getImmunizationDate(imm);
    const status = imm.status ?? "-";
    const newEntry: ImmunizationOccurrence = {
      rawImmunization: imm,
      date,
      status,
    };
    if (!code) {
      results.push({
        title,
        code: "-",
        mostRecentImmunization: newEntry,
        sortedOccurrences: [newEntry],
        status,
      });
      return;
    }

    const existing = immsMap.get(code);
    if (existing) {
      immsMap.set(code, [...existing, newEntry]);
    } else {
      immsMap.set(code, [newEntry]);
    }
  });

  [...immsMap.entries()].map(([code, values]) => {
    const sortedOccurrences = values.sort((a, b) => {
      const dateA = a.date ? new Date(a.date).getTime() : 0;
      const dateB = b.date ? new Date(b.date).getTime() : 0;

      return dateB - dateA;
    });

    const mostRecent =
      sortedOccurrences.find(o => o.status?.toLowerCase() !== "not-done") ?? sortedOccurrences[0];

    if (!mostRecent) return;
    results.push({
      code,
      title: getImmunizationsDisplay(mostRecent.rawImmunization),
      mostRecentImmunization: mostRecent,
      sortedOccurrences,
      status: mostRecent.status ?? "-",
    });
  });

  return results;
}

function getImmunizationRowData({
  immunizations,
  tableFilters,
}: {
  immunizations: GroupedImmunizations[];
  tableFilters: MrFilterSetting | undefined;
}): ImmunizationRowData[] {
  return immunizations
    ?.map(immunization => ({
      id: immunization.mostRecentImmunization.rawImmunization.id ?? "-",
      immunization: immunization.title,
      code: immunization.code,
      manufacturer:
        immunization.mostRecentImmunization.rawImmunization.manufacturer?.display ?? "-",
      date: immunization.mostRecentImmunization.date ?? "-",
      status: immunization.status,
    }))
    .filter(row => filterByDate(row.date, tableFilters?.dateFilter))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

function getImmunizationsDisplay(immunization: Immunization): string {
  const codings = getValidCode(immunization.vaccineCode?.coding);
  const displays = codings.map(coding => coding.display);

  if (displays.length) {
    return displays.join(", ");
  } else if (immunization.vaccineCode?.text) {
    return immunization.vaccineCode.text;
  }

  return "-";
}

function getImmunizationsCode(immunization: Immunization): string | undefined {
  const coding = getFirstCodeSpecified(immunization.vaccineCode?.coding, ["cvx", RX_NORM_CODE]);

  return coding ? `${coding.system}: ${coding.code}` : undefined;
}

function getImmunizationDate(immunizations: Immunization): string {
  return dayjs(immunizations.occurrenceDateTime).format(ISO_DATE);
}
