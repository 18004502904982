import { Location } from "@medplum/fhirtypes";
import { Divider, Box, BoxProps } from "@chakra-ui/react";
import { GrLocation } from "react-icons/gr";
import { References } from ".";
import { MdLibraryBooks } from "react-icons/md";
import { HiOutlineOfficeBuilding } from "react-icons/hi";

export function LocationReference({
  title,
  locations,
  ...restProps
}: {
  title?: string;
  locations: Location[];
} & BoxProps) {
  return (
    <Box {...restProps}>
      {locations.map((location, index) => {
        const showTitle = index === 0;

        return (
          <Box key={index}>
            <References
              title={showTitle ? title || "Location(s)" : undefined}
              references={[
                {
                  title: "Name",
                  value: location.name,
                  icon: MdLibraryBooks,
                },
                {
                  title: "Address",
                  value: getAddress(location),
                  icon: GrLocation,
                },
                {
                  title: "Type",
                  value: getLocationType(location),
                  icon: HiOutlineOfficeBuilding,
                },
              ]}
            />
            {index < locations.length - 1 && <Divider my={3} />}
          </Box>
        );
      })}
    </Box>
  );
}

function getAddress(location: Location): string | undefined {
  const address = location.address?.line?.map(line => line);

  if (!address) {
    return undefined;
  }

  return address.join(", ");
}

function getLocationType(location: Location): string | undefined {
  const type = location.type?.map(type => type.text);

  if (!type) {
    return undefined;
  }

  return type.join(", ");
}
