import {
  Box,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { PatientDTO } from "@metriport/api-sdk";
import { toArray } from "@metriport/shared";

export type isDownloading = Record<
  string,
  {
    type?: string;
    downloading: boolean;
  }
>;

export function DemographicsModal({
  isOpen,
  patient,
  actions,
}: {
  isOpen: boolean;
  patient: PatientDTO | undefined;
  actions: {
    onCloseModal: () => void;
  };
}) {
  const addresses = toArray(patient?.address);
  const emails = toArray(patient?.contact).flatMap(contact => contact?.email || []);
  const phones = toArray(patient?.contact).flatMap(contact => contact?.phone || []);

  return (
    <Modal isOpen={isOpen} onClose={actions.onCloseModal} size="3xl">
      <ModalOverlay />
      <ModalContent m={5} p={6} borderWidth="1px" borderRadius="lg" shadow="md">
        <ModalCloseButton />
        <Box as="section" p={5}>
          <Text mb={2} fontSize="2xl" fontWeight="bold" textAlign="center">
            {patient?.firstName} {patient?.lastName}
          </Text>

          <Text fontSize="md" textAlign="center">
            {patient?.id}
          </Text>

          <Divider borderColor={useColorModeValue("gray.300", "gray.600")} my={3} />
          <SimpleGrid columns={[1, 2, 2]} textAlign="left">
            <Box p={2}>
              <Text fontWeight="semibold">Date of Birth:</Text>
              <Text fontSize="md">{patient?.dob}</Text>
            </Box>

            <Box p={2}>
              <Text fontWeight="semibold">Gender at Birth:</Text>
              <Text fontSize="md">{patient?.genderAtBirth}</Text>
            </Box>

            {addresses.map((address, index) => (
              <Box key={index} p={2}>
                <Text fontWeight="semibold">
                  Address{addresses.length > 1 ? ` ${index + 1}` : ""}:
                </Text>
                <Text fontSize="md">
                  {address?.addressLine2 ? `${address?.addressLine2}, ` : ""}
                  {`${address?.addressLine1}, ${address?.city}, ${address?.state}, ${address?.zip}`}
                </Text>
              </Box>
            ))}

            {emails.map((email, index) => (
              <Box key={index} p={2}>
                <Text fontWeight="semibold">Email{emails.length > 1 ? ` ${index + 1}` : ""}:</Text>
                <Text fontSize="md">{email}</Text>
              </Box>
            ))}

            {phones.map((phone, index) => (
              <Box key={index} p={2}>
                <Text fontWeight="semibold">Phone{phones.length > 1 ? ` ${index + 1}` : ""}:</Text>
                <Text fontSize="md">{phone}</Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </ModalContent>
    </Modal>
  );
}
