import { Encounter } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { ReportRowData } from "./table-data";
import { ReportContent as Content } from "./report-content";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { ReferencesDisplay } from "../shared/references";

export function ReportContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & ReportRowData) {
  const selectedEncounter = mappedConsolidated?.["Encounter"]?.[
    rowContents.report.encounter.id ?? ""
  ] as Encounter | undefined;

  const valueString = selectedEncounter?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Note"
      sourceDocument={{
        id: selectedEncounter?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedEncounter, null, 2) : undefined}
    >
      <Content encounterNote={rowContents.report} />
      <ReferencesDisplay
        encounters={{
          refs: selectedEncounter ? [selectedEncounter] : undefined,
        }}
        locations={{
          refs: rowContents.report.locations,
        }}
        practitioners={{
          refs: rowContents.report.participants,
        }}
        conditions={{
          refs: rowContents.report.diagnoses,
        }}
      />
    </SidePanelContent>
  );
}
