import { EhrDetails } from "../../../../domain/ehr";

export type SharedState = {
  authToken?: string;
  baseUrl?: string;
  ehrDetails?: EhrDetails;
  isLoaded?: boolean;
};

export enum SharedStateActionType {
  set,
}

export type SharedStateAction = {
  type: SharedStateActionType.set;
  authToken: string;
  baseUrl: string;
  ehrDetails: EhrDetails | undefined;
  isLoaded: boolean;
};

export const reducer = (state: SharedState, action: SharedStateAction) => {
  switch (action.type) {
    case SharedStateActionType.set: {
      return {
        ...state,
        authToken: action.authToken,
        baseUrl: action.baseUrl,
        ehrDetails: action.ehrDetails,
        isLoaded: action.isLoaded,
      };
    }

    default:
      return state;
  }
};

export const initialState: SharedState = {};
