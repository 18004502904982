import { Encounter, Location } from "@medplum/fhirtypes";
import { ISO_DATE } from "@metriport/shared/common/date";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, filterByDate, getResourcesFromBundle } from "../shared";
export type EncounterRowData = {
  id: string;
  // encounter: string;
  location: string;
  class: string;
  startDate: string;
  endDate: string;
};

export const encounterTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<EncounterRowData>[] = [
    { field: "id", hide: true },
    { field: "class" },
    { field: "location" },
    { field: "startDate", sort: tableFilters?.stringFilter ? undefined : "desc" },
    { field: "endDate" },
  ];

  const encounters = getResourcesFromBundle<Encounter>(bundle, "Encounter");
  const locations = getResourcesFromBundle<Location>(bundle, "Location");

  return {
    columnDefs,
    rowData: getEncounterRowData({ encounters, locations, tableFilters }),
  };
};

function getEncounterRowData({
  encounters,
  locations,
  tableFilters,
}: {
  encounters: Encounter[];
  locations: Location[];
  tableFilters: MrFilterSetting | undefined;
}): EncounterRowData[] {
  return encounters
    ?.map(encounter => ({
      id: encounter.id ?? "-",
      // encounter: getEncountersDisplay(encounter),
      location: getEncounterLocation(encounter, locations),
      class: renderClassDisplay(encounter),
      startDate: encounter.period?.start ? dayjs(encounter.period.start).format(ISO_DATE) : "-",
      endDate: encounter.period?.end ? dayjs(encounter.period.end).format(ISO_DATE) : "-",
    }))
    .filter(row => filterByDate(row.startDate, tableFilters?.dateFilter))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

// TODO: Re-introduce when #2357 is resolved
// function getEncountersDisplay(encounter: Encounter): string {
//   const reasons: string[] = [];

//   for (const reason of encounter.reasonCode ?? []) {
//     const text = reason.text;

//     if (text) {
//       reasons.push(text);
//     }
//   }

//   if (reasons.length) {
//     return reasons.join(", ");
//   } else {
//     const codings = getValidCode(encounter.reasonCode?.[0]?.coding);

//     if (codings.length && codings[0]?.display) {
//       return codings[0].display;
//     }
//   }

//   return "-";
// }

function getEncounterLocation(encounter: Encounter, locations: Location[]): string {
  const locationId = encounter.location?.[0]?.location?.reference?.split("/")?.[1];
  const location = locations.find(l => l.id === locationId);
  return location?.name ?? "-";
}

function renderClassDisplay(encounter: Encounter): string {
  const isDisplayIsNotValid =
    encounter.class?.display === undefined || encounter.class?.display === "unknown";

  if (encounter.class?.display && !isDisplayIsNotValid) {
    return encounter.class?.display;
  } else if (encounter.class?.code && !isDisplayIsNotValid) {
    const extension = encounter.class?.extension?.find(coding => {
      return coding.valueCoding?.code === encounter.class?.code;
    });

    return extension?.valueCoding?.display ?? "-";
  } else {
    return "-";
  }
}
