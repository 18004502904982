import { useMemo } from "react";
import { MetriportMedicalApi } from "@metriport/api-sdk";
import axios from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useSharedContext } from "./shared-context";

dayjs.extend(duration);

const mapiTimeout = dayjs.duration({ seconds: 29 });
const headers: Record<string, string> = {
  [MetriportMedicalApi.headers.clientApp]: "Metriport-Dashboard",
};

export function useMetriportApi(): MetriportMedicalApi {
  const { sharedState } = useSharedContext();

  const metriportAPI = useMemo(() => {
    const options = {
      axios,
      timeout: mapiTimeout.asMilliseconds(),
      mode: "jwt" as const,
      baseAddress: sharedState.baseUrl,
      ...headers,
    };
    return new MetriportMedicalApi(sharedState.authToken ?? "", options);
  }, [sharedState.authToken, sharedState.baseUrl]);

  return metriportAPI;
}
