import { useEffect, useState } from "react";
import { MedicalRecordsStatusDTO } from "@metriport/api-sdk";
import { QueryStatus } from "@metriport/api-sdk/medical/models/patient";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMetriportApi } from "./useMetriportApi";
import useMetriportToast from "./useMetriportToast";
import { useInterval } from "./use-interval";
// TODO: 2064 Will add to shared when its used by monorepo
import { capture } from "../../../shared/capture";
import { getMedicalRecordStatus, getMedicalRecordUrl } from "../../../api/medical-record";

dayjs.extend(duration);

const POLLING_INTERVAL = dayjs.duration({ seconds: 3 });

export const mrFormats = ["html", "pdf"] as const;
export type MRFormats = (typeof mrFormats)[number];

export type isMrDownloading = Record<
  string,
  {
    type?: string;
    downloading: boolean;
  }
>;

type UseGetMedicalRecord = {
  existingMrStatus: MedicalRecordsStatusDTO | undefined;
  isMrDownloading: isMrDownloading;
  onDownloadMR: (
    downloadExistingMr: boolean,
    conversionType: MRFormats,
    dateTo: string,
    dateFrom: string
  ) => Promise<void>;
};

export function useGetMedicalRecord({ patientId }: { patientId: string }): UseGetMedicalRecord {
  const metriportApi = useMetriportApi();
  const toast = useMetriportToast();

  const [existingMrStatus, setExistingMrStatus] = useState<MedicalRecordsStatusDTO | undefined>(
    undefined
  );
  const [isMrDownloading, setIsMrDownloading] = useState<isMrDownloading>({});
  const [shouldPoll, setShouldPoll] = useState(false);

  useEffect(() => {
    fetchMedicalRecordStatus();
  }, []);

  async function onDownloadMR(
    downloadExistingMr: boolean,
    conversionType: MRFormats,
    dateTo: string,
    dateFrom: string
  ): Promise<void> {
    setIsMrDownloading({
      ...isMrDownloading,
      [conversionType]: {
        downloading: true,
      },
    });

    if (downloadExistingMr) {
      setShouldPoll(true);
      toast.info({
        title: "Hold tight... This might take a while.",
        duration: 5000,
      });
      const dateToUndefined = dateTo !== "" ? dateTo : undefined;
      const dateFromUndefined = dateFrom !== "" ? dateFrom : undefined;
      metriportApi.startConsolidatedQuery(
        patientId,
        undefined,
        dateFromUndefined,
        dateToUndefined,
        conversionType
      );
    } else {
      await getMedicalRecord(conversionType);
    }
  }

  function checkPopupEnabled() {
    const testWindow = window.open("", "_blank", "width=100,height=100");

    if (testWindow) {
      testWindow.close();
    } else {
      toast.warning({
        title:
          "Make sure the pop ups are enabled for this site to view the Medical Record Summary.",
        duration: 5000,
      });
    }
  }

  useInterval(
    async () => {
      const { queries } = await metriportApi.getConsolidatedQueryStatus(patientId);

      if (queries) {
        const allDone = queries.every(query => query.status !== "processing");

        if (allDone) {
          for (const query of queries) {
            if (query.conversionType === "html" || query.conversionType === "pdf") {
              await processMrCreation(query.conversionType, query.status);
            }
          }

          setShouldPoll(false);

          const isDownloadingUpdated = queries.reduce((acc, query) => {
            if (query.conversionType === "html" || query.conversionType === "pdf") {
              return {
                ...acc,
                [query.conversionType]: {
                  downloading: false,
                },
              };
            }

            return acc;
          }, {});

          setIsMrDownloading({
            ...isMrDownloading,
            ...isDownloadingUpdated,
          });
        }
      }
    },
    shouldPoll ? POLLING_INTERVAL.asMilliseconds() : null
  );

  async function processMrCreation(conversionType: MRFormats, queryStatus: QueryStatus) {
    const mrStatus = await fetchMedicalRecordStatus();
    if (queryStatus === "completed" && doesMrExist(mrStatus)) {
      await getMedicalRecord(conversionType);
    }

    if (queryStatus === "failed" || !doesMrExist(mrStatus)) {
      toast.warning({
        title: "No content to generate a Medical Record Summary.",
        duration: 5000,
      });
    }
  }

  async function fetchMedicalRecordStatus(): Promise<MedicalRecordsStatusDTO | undefined> {
    try {
      const mrStatus = await getMedicalRecordStatus(patientId);
      if (mrStatus) {
        setExistingMrStatus(mrStatus);
      }
      return mrStatus;
    } catch (error) {
      capture.error(error, { extra: { context: "document.fetchMRInfo" } });
      return;
    }
  }

  async function getMedicalRecord(conversionType: MRFormats): Promise<void> {
    try {
      const url = await getMedicalRecordUrl(patientId, conversionType);

      if (!url) {
        toast.info({
          title: "No medical resources were found for this patient.",
          duration: 5000,
        });
        return;
      }

      checkPopupEnabled();
      // TODO move this to a helper function
      const a = document.createElement("a");
      a.href = url;
      a.download = patientId + "_MR";
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      capture.error(err, { extra: { patientId, context: `document.existingMR.download` } });
      toast.error();
    } finally {
      setIsMrDownloading({
        ...isMrDownloading,
        [conversionType]: {
          downloading: false,
        },
      });
    }
  }

  return { onDownloadMR, isMrDownloading, existingMrStatus };
}

function doesMrExist(mrStatus: MedicalRecordsStatusDTO | undefined): boolean {
  return (mrStatus && Object.keys(mrStatus).length > 0) ?? false;
}
