import { AllergyIntolerance, Practitioner } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { AllergyRowData } from "./table-data";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { showPeriodDate } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { getReferenceResource } from "../shared";
import { ReferencesDisplay } from "../shared/references";

export function AllergyContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & AllergyRowData) {
  const selectedAllergy = mappedConsolidated?.["AllergyIntolerance"]?.[rowContents.id] as
    | AllergyIntolerance
    | undefined;

  const valueString = selectedAllergy?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Allergy"
      sourceDocument={{
        id: selectedAllergy?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedAllergy, null, 2) : undefined}
    >
      <AllergyDisplay
        allergy={selectedAllergy}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function AllergyDisplay({
  allergy,
  tableRow,
  mappedConsolidated,
}: {
  allergy: AllergyIntolerance | undefined;
  tableRow: AllergyRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!allergy) {
    return null;
  }

  const practitionerReference = getReferenceResource<Practitioner>(
    allergy.recorder,
    "Practitioner",
    mappedConsolidated
  );

  return (
    <>
      <DetailsDisplay
        details={{
          allergy: tableRow.allergy,
          manifestation: tableRow.manifestation,
          status: tableRow.status,
          criticality: allergy.criticality,
          period: showPeriodDate({
            period: allergy.onsetPeriod,
            fallbackDate: allergy.onsetDateTime,
          }),
        }}
      />
      <CodesDisplay code={allergy.reaction?.flatMap(r => r.substance || [])} />
      <ReferencesDisplay
        practitioners={{
          title: "Performer",
          refs: practitionerReference ? [practitionerReference] : undefined,
        }}
      />
    </>
  );
}
