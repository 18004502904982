import { z } from "zod";
import { apiOSS } from "./apiOSS";

const PATIENT_URL = `/medical/v1/patient`;

const patientHieOptOutSchema = z.object({
  id: z.string(),
  hieOptOut: z.boolean(),
  message: z.string(),
});

export type PatientHieOptOut = z.infer<typeof patientHieOptOutSchema>;

export async function updatePatientHieOptOut(
  patientId: string,
  hieOptOut: boolean
): Promise<PatientHieOptOut> {
  const resp = await apiOSS.put(`${PATIENT_URL}/${patientId}/hie-opt-out`, null, {
    params: { hieOptOut },
  });

  return patientHieOptOutSchema.parse(resp.data);
}

export async function getPatientHieOptOut(patientId: string): Promise<PatientHieOptOut> {
  const resp = await apiOSS.get(`${PATIENT_URL}/${patientId}/hie-opt-out`);

  return patientHieOptOutSchema.parse(resp.data);
}
