import { Encounter } from "@medplum/fhirtypes";
import { Tag, Box, BoxProps } from "@chakra-ui/react";
import { GrStatusUnknown } from "react-icons/gr";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BsPersonSquare } from "react-icons/bs";
import { ISO_DATE } from "@metriport/shared/common/date";
import { formatDate } from "../../../../shared/date";
import { References } from ".";

export function EncounterReference({
  encounters,
  ...restProps
}: {
  encounters: Encounter[];
} & BoxProps) {
  return (
    <Box {...restProps}>
      {encounters.map(encounter => {
        const encounterType = encounter.type?.map(type => type.text).join(", ") ?? undefined;
        const encounterStatus = encounter.status;
        const periodStart = encounter.period?.start;
        const periodEnd = encounter.period?.end;
        const periodDisplay = getPeriodDisplay(periodStart, periodEnd);

        return (
          <References
            key={encounter.id}
            title="Encounter"
            references={[
              {
                title: "Type",
                value: encounterType,
                icon: BsPersonSquare,
              },
              {
                title: "Period",
                value: periodDisplay,
                icon: FaRegCalendarAlt,
              },
              {
                title: "Status",
                value: (
                  <Tag size="sm" variant="subtle" colorScheme={setStatusCodeColor(encounterStatus)}>
                    {encounterStatus}
                  </Tag>
                ),
                icon: GrStatusUnknown,
              },
            ]}
          />
        );
      })}
    </Box>
  );
}

function getPeriodDisplay(start: string | undefined, end: string | undefined) {
  if (!start && !end) {
    return "Unknown";
  }

  if (!start) {
    return `${formatDate(end ?? "", ISO_DATE)}`;
  }

  if (!end) {
    return `${formatDate(start ?? "", ISO_DATE)}`;
  }

  return `${formatDate(start ?? "", ISO_DATE)} - ${formatDate(end ?? "", ISO_DATE)}`;
}

function setStatusCodeColor(status: string | undefined) {
  switch (status) {
    case "planned":
    case "arrived":
    case "onleave":
    case "triaged":
      return "yellow";
    case "finished":
      return "green";
    case "cancelled":
    case "entered-in-error":
      return "red";
    default:
      return "gray";
  }
}
