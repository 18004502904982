import { FamilyMemberHistory } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { FamilyMemberHistoryRowData } from "./table-data";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";

export function FamilyMemberHistoryContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & FamilyMemberHistoryRowData) {
  const selectedFamilyMemberHistory = mappedConsolidated?.["FamilyMemberHistory"]?.[
    rowContents.id
  ] as FamilyMemberHistory | undefined;

  const valueString =
    selectedFamilyMemberHistory?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Family Member History"
      sourceDocument={{
        id: selectedFamilyMemberHistory?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedFamilyMemberHistory, null, 2) : undefined}
    >
      <FamilyMemberHistoryDisplay
        familyMemberHistory={selectedFamilyMemberHistory}
        tableRow={rowContents}
      />
    </SidePanelContent>
  );
}

function FamilyMemberHistoryDisplay({
  familyMemberHistory,
  tableRow,
}: {
  familyMemberHistory: FamilyMemberHistory | undefined;
  tableRow: FamilyMemberHistoryRowData;
}) {
  if (!familyMemberHistory) {
    return null;
  }

  return (
    <>
      <DetailsDisplay
        details={{
          "Family Member": tableRow.familyMember,
          sex: tableRow.sex,
          conditions: tableRow.conditions,
          deceased: tableRow.deceased,
        }}
      />
      <CodesDisplay code={familyMemberHistory?.reasonCode} />
    </>
  );
}
