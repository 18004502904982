import { Immunization, Organization, Practitioner } from "@medplum/fhirtypes";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { getReferenceResource, getResourcesFromBundle } from "../shared";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";
import { OccurenceDisplayText } from "../shared/occurrences";
import { ReferencesDisplay } from "../shared/references";
import { SidePanelSection } from "../shared/sidepanel-section";
import { GroupedImmunizations, ImmunizationRowData, groupImmunizations } from "./table-data";

export function ImmunizationContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & ImmunizationRowData) {
  const immunizations = getResourcesFromBundle<Immunization>(mappedConsolidated, "Immunization");

  const groupedImmunizations = groupImmunizations(immunizations);
  const selectedImmunization = groupedImmunizations.find(
    immunizations => immunizations.mostRecentImmunization.rawImmunization.id === rowContents.id
  );

  if (!selectedImmunization) {
    return null;
  }

  const mostRecentImmunization = selectedImmunization.mostRecentImmunization;

  const sourceFileName =
    mostRecentImmunization?.rawImmunization.extension?.find(ext => ext.valueString)?.valueString ??
    "";

  return (
    <SidePanelContent
      title="Immunization"
      sourceDocument={{
        id: mostRecentImmunization?.rawImmunization.id ?? "",
        fileName: sourceFileName,
      }}
      fhirJson={isImpersonating ? JSON.stringify(mostRecentImmunization, null, 2) : undefined}
    >
      <ImmunizationDisplay
        immunization={selectedImmunization}
        tableRow={rowContents}
        mappedConsolidated={mappedConsolidated}
      />
    </SidePanelContent>
  );
}

function ImmunizationDisplay({
  immunization,
  tableRow,
  mappedConsolidated,
}: {
  immunization: GroupedImmunizations | undefined;
  tableRow: ImmunizationRowData;
  mappedConsolidated: MappedConsolidatedResources | undefined;
}) {
  if (!immunization) {
    return null;
  }

  const mostRecent = immunization.mostRecentImmunization;
  const doseQuantity = mostRecent.rawImmunization.doseQuantity;
  const hasDoseQuantity = doseQuantity?.value && doseQuantity?.unit;

  const organizationActor = mostRecent.rawImmunization.performer?.find(performer =>
    performer.actor?.reference?.startsWith("Organization/")
  );

  const practitionerActor = mostRecent.rawImmunization.performer?.find(performer =>
    performer.actor?.reference?.startsWith("Practitioner/")
  );

  const practitionerReference = getReferenceResource<Practitioner>(
    practitionerActor?.actor,
    "Practitioner",
    mappedConsolidated
  );

  const organizationReference = getReferenceResource<Organization>(
    organizationActor?.actor,
    "Organization",
    mappedConsolidated
  );

  return (
    <>
      <DetailsDisplay
        details={{
          immunization: tableRow.immunization,
          date: immunization.mostRecentImmunization.date,
          manufacturer: tableRow.manufacturer,
          lotNumber: mostRecent.rawImmunization.lotNumber,
          dose: hasDoseQuantity ? `${doseQuantity?.value} ${doseQuantity?.unit}` : "-",
          status: immunization.status,
        }}
      />
      <CodesDisplay code={mostRecent.rawImmunization.vaccineCode} />
      {immunization.sortedOccurrences && immunization.sortedOccurrences.length > 1 && (
        <>
          <SidePanelSection title="Instances">
            {immunization.sortedOccurrences.map((p, index) =>
              OccurenceDisplayText(p.date, p.status, index)
            )}
          </SidePanelSection>
        </>
      )}
      <ReferencesDisplay
        practitioners={{
          refs: practitionerReference ? [practitionerReference] : undefined,
        }}
        organizations={{
          refs: organizationReference ? [organizationReference] : undefined,
        }}
      />
    </>
  );
}
