import { ChakraProps, Text, ThemingProps, Tooltip } from "@chakra-ui/react";
import { ReactNode } from "react";

export type Tooltip = {
  tooltip?: string;
};

export const Label = ({
  tooltip,
  children,
  ...rest
}: {
  children?: ReactNode;
} & Tooltip &
  ChakraProps &
  ThemingProps<"Text">) => {
  return (
    <Tooltip label={tooltip}>
      <Text whiteSpace="nowrap" mt={5} mb={2} mr={3} {...rest}>
        {children}
      </Text>
    </Tooltip>
  );
};
