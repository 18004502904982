import { ButtonProps, Button as ChakraButton, ChakraProps } from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { METRIPORT_PRIMARY_FADED } from "../shared-logic/style";

export const METRIPORT_PRIMARY = "#748df0";
export const METRIPORT_PRIMARY_HOVER_BG = "#879ced";

export const defaultButtonSettings = {
  bg: METRIPORT_PRIMARY,
  color: "white",
  _hover: {
    bg: METRIPORT_PRIMARY_HOVER_BG,
  },
};

export const Button = forwardRef<
  HTMLButtonElement,
  {
    type?: "button" | "reset" | "submit";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    isLoading?: boolean;
    loadingText?: string;
    disabled?: boolean;
    children?: React.ReactNode;
  } & ChakraProps &
    ButtonProps
>(({ type, onClick, isLoading, loadingText, disabled, children, ...rest }, ref) => {
  return (
    <ChakraButton
      ref={ref}
      {...(rest.colorScheme
        ? undefined
        : {
            bg: rest.variant === "outline" ? "transparent" : METRIPORT_PRIMARY,
            color: rest.variant === "outline" ? METRIPORT_PRIMARY : "white",
            borderColor: rest.variant === "outline" ? METRIPORT_PRIMARY : "transparent",
            _hover: {
              bg: rest.variant === "outline" ? "transparent" : METRIPORT_PRIMARY_FADED,
              color: rest.variant === "outline" ? METRIPORT_PRIMARY_FADED : "white",
              borderColor: rest.variant === "outline" ? METRIPORT_PRIMARY_FADED : "transparent",
            },
          })}
      onClick={onClick}
      isLoading={isLoading ?? false}
      loadingText={loadingText}
      isDisabled={disabled ?? false}
      type={type}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
});
