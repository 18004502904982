import { Flex, Box, Heading, Text, Icon, Grid, GridItem, GridProps } from "@chakra-ui/react";
import { IconType } from "react-icons";

export type References = {
  title: string;
  value: string | React.ReactElement | undefined;
  icon: IconType;
}[];

export function References({
  title,
  references,
  ...restProps
}: {
  title?: string;
  references: References;
  actions?: {
    onGoToResource: () => void;
    onViewMore: () => void;
  };
} & GridProps) {
  return (
    <Box>
      {title && (
        <Heading textDecoration={"underline"} size={"md"} mb={2}>
          {title}
        </Heading>
      )}
      <Grid templateColumns="repeat(2, 1fr)" gap={4} {...restProps}>
        {references.map((reference, index) => (
          <DataRow
            title={reference.title}
            value={reference.value}
            icon={reference.icon}
            key={index}
          />
        ))}
      </Grid>
    </Box>
  );
}

export function DataRow({
  title,
  value,
  icon,
}: {
  title: string;
  value: string | React.ReactElement | undefined;
  icon?: IconType;
}) {
  return (
    <GridItem>
      <Flex flexDirection={"row"} alignItems={"center"}>
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
        <Box>
          <Text fontWeight={"bold"}>{title}</Text>
          <Text fontSize={"sm"}>{value ?? "N/A"}</Text>
        </Box>
      </Flex>
    </GridItem>
  );
}
