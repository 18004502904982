import { Bundle, Resource, DocumentReference } from "@medplum/fhirtypes";

export type ResourceMap = {
  [resourceId: string]: Resource;
};

export type MappedConsolidatedResources = {
  [resourceType: string]: ResourceMap;
};

export type PatientConsolidatedState = {
  [patientId: string]: {
    consolidated?: MappedConsolidatedResources;
    documents?: DocumentReference[];
    lastDocQuery?: string | undefined;
  };
};

export enum PatientConsolidatedStateActionType {
  setPatientsDocuments,
  setPatientsConsolidated,
  resetPatientsConsolidated,
}

export type PatientQueryParams = {
  patientTab: PatientTab;
  sectionName: string | undefined;
  resourceId: string | undefined;
};

export type PatientQueryParamProps = PatientQueryParams & {
  setPatientQueryParams: ({
    sectionName,
    resourceId,
    patientTab,
  }: Partial<PatientQueryParams>) => void;
  clearPatientQueryParams: () => void;
};

export const patientTabs = ["fhir", "documents", "matches"] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPatientTab(tab: any): tab is PatientTab {
  return patientTabs.includes(tab);
}

export type PatientTab = (typeof patientTabs)[number];
export const defaultPatientTab: PatientTab = "fhir";

export function getValidPatientTab(tab: string | undefined | null): PatientTab {
  if (tab && isPatientTab(tab)) return tab;
  return defaultPatientTab;
}

export type PatientConsolidatedStateAction =
  | {
      type: PatientConsolidatedStateActionType.setPatientsConsolidated;
      patientId: string;
      consolidatedBundle: Bundle<Resource>;
    }
  | {
      type: PatientConsolidatedStateActionType.setPatientsDocuments;
      patientId: string;
      documents: DocumentReference[];
      lastDocQuery: string | undefined;
    }
  | {
      type: PatientConsolidatedStateActionType.resetPatientsConsolidated;
      patientId: string;
    };

export const reducer = (
  state: PatientConsolidatedState,
  action: PatientConsolidatedStateAction
) => {
  switch (action.type) {
    case PatientConsolidatedStateActionType.setPatientsConsolidated: {
      const mappedResources: {
        [resourceType: string]: {
          [resourceId: string]: Resource;
        };
      } = {};

      action.consolidatedBundle.entry?.forEach(entry => {
        const resource = entry.resource;
        if (resource) {
          if (!mappedResources[resource.resourceType]) {
            mappedResources[resource.resourceType] = {};
          }

          const mappedResource = mappedResources[resource.resourceType];

          if (mappedResource && resource.id) {
            mappedResource[resource.id] = resource;
          }
        }
      });

      return {
        ...state,
        [action.patientId]: {
          ...state[action.patientId],
          consolidated: mappedResources,
        },
      };
    }

    case PatientConsolidatedStateActionType.setPatientsDocuments: {
      return {
        ...state,
        [action.patientId]: {
          ...state[action.patientId],
          documents: action.documents,
          lastDocQuery: action.lastDocQuery,
        },
      };
    }

    case PatientConsolidatedStateActionType.resetPatientsConsolidated: {
      const newState = { ...state };
      delete newState[action.patientId];
      return newState;
    }

    default:
      return state;
  }
};

export const initialState: PatientConsolidatedState = {};
