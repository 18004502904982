import * as Sentry from "@sentry/react";
import { Extras, ScopeContext } from "@sentry/types";
import { getEnvType } from "./util";

export type SeverityLevel = "fatal" | "error" | "warning" | "log" | "info" | "debug";

export type UserData = Pick<Sentry.User, "id" | "email">;

export const capture = {
  environment: getEnvType(),

  setUser: (user: UserData): void => {
    Sentry.setUser(user);
  },
  /**
   * Captures an exception event and sends it to Sentry.
   *
   * @param error — An Error object.
   * @param captureContext — Additional scope data to apply to exception event.
   * @returns — The generated eventId.
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (error: any, captureContext?: Partial<ScopeContext>): string => {
    if (typeof error === "string") {
      return capture.message(error, {
        ...captureContext,
        level: captureContext?.level ?? "error",
      });
    }
    const extra = captureContext ? stringifyExtra(captureContext) : {};
    return Sentry.captureException(error, {
      ...captureContext,
      extra,
    });
  },

  /**
   * Captures an exception event and sends it to Sentry.
   *
   * @param message The message to send to Sentry.
   * @param captureContext — Additional scope data to apply to exception event.
   * @returns — The generated eventId.
   */
  message: (message: string, captureContext?: Partial<ScopeContext>): string => {
    const extra = captureContext ? stringifyExtra(captureContext) : {};
    return Sentry.captureMessage(message, {
      ...captureContext,
      extra,
    });
  },

  getEnvironment: () => {
    return capture.environment;
  },
};

function stringifyExtra(captureContext: Partial<ScopeContext>): Extras {
  return Object.entries(captureContext.extra ?? {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === "string" ? value : JSON.stringify(value, null, 2),
    }),
    {}
  );
}
