import { Coverage } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { CoverageRowData } from "./table-data";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { showPeriodDate } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";

export function CoverageContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & CoverageRowData) {
  const selectedCoverage = mappedConsolidated?.["Coverage"]?.[rowContents.id] as
    | Coverage
    | undefined;

  const valueString = selectedCoverage?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Coverage"
      sourceDocument={{
        id: selectedCoverage?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedCoverage, null, 2) : undefined}
    >
      <CoverageDisplay coverage={selectedCoverage} tableRow={rowContents} />
    </SidePanelContent>
  );
}

function CoverageDisplay({
  coverage,
  tableRow,
}: {
  coverage: Coverage | undefined;
  tableRow: CoverageRowData;
}) {
  if (!coverage) {
    return null;
  }

  return (
    <>
      <DetailsDisplay
        details={{
          provider: tableRow.provider,
          "Policy ID": tableRow.policyId,
          status: coverage.status,
          period: showPeriodDate({
            period: coverage.period,
          }),
        }}
      />
      <CodesDisplay code={coverage?.type} />
    </>
  );
}
