import { Coverage, Organization } from "@medplum/fhirtypes";
import { ColDef } from "ag-grid-community";
import { GenerateTableDataParams } from "..";
import { MrFilterSetting } from "../../../../../api/settings";
import { compare, getResourcesFromBundle } from "../shared";

export type CoverageRowData = {
  id: string;
  provider: string;
  policyId: string;
  status: string;
};

export const coverageTableData = ({ bundle, tableFilters }: GenerateTableDataParams) => {
  const columnDefs: ColDef<CoverageRowData>[] = [
    { field: "id", hide: true },
    { field: "provider" },
    { field: "policyId" },
    { field: "status" },
  ];

  const coverages = getResourcesFromBundle<Coverage>(bundle, "Coverage");
  const organizations = getResourcesFromBundle<Organization>(bundle, "Organization");

  return {
    columnDefs,
    rowData: getCoverageRowData({ coverages, organizations, tableFilters }),
  };
};

function getCoverageRowData({
  coverages,
  organizations,
  tableFilters,
}: {
  coverages: Coverage[];
  organizations: Organization[];
  tableFilters: MrFilterSetting | undefined;
}): CoverageRowData[] {
  return coverages
    ?.map(coverage => ({
      id: coverage.id ?? "-",
      provider: getCoverageOrganization(coverage, organizations),
      policyId: getPolicyId(coverage),
      status: coverage.status ?? "-",
    }))
    .sort((a, b) => compare(a, b, tableFilters?.stringFilter));
}

function getCoverageOrganization(coverage: Coverage, organizations: Organization[]): string {
  const payorRef = coverage.payor?.[0]?.reference?.split("/")?.[1];

  if (payorRef) {
    const organization = organizations.find(org => org.id === payorRef);
    if (organization) {
      return organization.name ?? "-";
    }
  }

  return "-";
}

function getPolicyId(coverage: Coverage): string {
  const policyIds: string[] = [];

  if (coverage.identifier) {
    for (const identifier of coverage.identifier) {
      if (identifier.value) {
        policyIds.push(identifier.value);
      }
    }
  }

  return policyIds.join(", ");
}
