import { Observation } from "@medplum/fhirtypes";
import { SidePanelContent } from "../../side-panel/side-panel-content";
import { ObservationRowData } from "./table-data";
import { MappedConsolidatedResources } from "../../../shared-logic/consolidated-context/reducer";
import { showPeriodDate } from "../shared/period";
import { CodesDisplay } from "../shared/codes";
import { DetailsDisplay } from "../shared/details";

export function ObservationContent({
  mappedConsolidated,
  isImpersonating,
  ...rowContents
}: {
  isImpersonating?: boolean;
  mappedConsolidated: MappedConsolidatedResources | undefined;
} & ObservationRowData) {
  const selectedObservation = mappedConsolidated?.["Observation"]?.[rowContents.id] as
    | Observation
    | undefined;

  const valueString =
    selectedObservation?.extension?.find(ext => ext.valueString)?.valueString ?? "";

  return (
    <SidePanelContent
      title="Observation"
      sourceDocument={{
        id: selectedObservation?.id ?? "",
        fileName: valueString,
      }}
      fhirJson={isImpersonating ? JSON.stringify(selectedObservation, null, 2) : undefined}
    >
      <ObservationDisplay observation={selectedObservation} tableRow={rowContents} />
    </SidePanelContent>
  );
}

function ObservationDisplay({
  observation,
  tableRow,
}: {
  observation: Observation | undefined;
  tableRow: ObservationRowData;
}) {
  if (!observation) {
    return null;
  }

  return (
    <>
      <DetailsDisplay
        details={{
          observation: tableRow.observation,
          value: tableRow.value,
          status: observation.status,
          period: showPeriodDate({
            period: observation.effectivePeriod,
            fallbackDate: observation.effectiveDateTime,
          }),
        }}
      />
      <CodesDisplay code={observation.code} />
    </>
  );
}
